import React from "react";
import DisplaySelectedFilter from "./DisplaySelectedFilter/DisplaySelectedFilter";
import {
  OUT_OF_STOCK,
  IN_STOCK,
  OVER_STOCKED,
  LOW_STOCK,
  productStatusColorMapping,
  getOutOfStockProducts,
  getLowStockProducts,
  getInStockProducts,
  getOverStockedProducts,
} from "../../../Utils/SlabPredictionFormUtils";
import "./SlabPredictionFilters.scss";
import { Tooltip } from "antd";

function SlabPredictionFilters(props) {
  const {
    selectedFilter,
    onSelectFilter,
    onClearFilter,
    displayPredictionTable,
  } = props;
  let lowStockProducts = [];
  let inStockProducts = [];
  let overStockedProducts = [];

  lowStockProducts = displayPredictionTable?.filter(
    (entry) => entry?.status === LOW_STOCK
  );
  inStockProducts = displayPredictionTable?.filter(
    (entry) => entry?.status === IN_STOCK
  );
  overStockedProducts = displayPredictionTable?.filter(
    (entry) => entry?.status === OVER_STOCKED
  );

  const onClickSelectFilter = (value) => {
    onSelectFilter(value);
  };

  return (
    <div className="slab-prediction-filter-wrapper">
      {selectedFilter?.includes(OVER_STOCKED) ? (
        <DisplaySelectedFilter
          productStatusColor={productStatusColorMapping[OVER_STOCKED]}
          productCount={overStockedProducts.length ?? 0}
          productStatus={OVER_STOCKED}
          onClickCloseFilter={() => {
            if (overStockedProducts.length > 0) onClearFilter(OVER_STOCKED);
          }}
        />
      ) : (
        <Tooltip
          placement="top"
          title={OVER_STOCKED}
          style={{ fontFamily: "AvertaStd-Semibold" }}
        >
          <div className="slab-filter-content">
            <div className="red-dot" />
            <div
              className="slab-filter-count"
              onClick={() => {
                if (overStockedProducts.length > 0)
                  onClickSelectFilter(OVER_STOCKED);
              }}
            >
              {" "}
              {overStockedProducts?.length ?? 0}
            </div>
          </div>{" "}
        </Tooltip>
      )}
      {selectedFilter?.includes(LOW_STOCK) ? (
        <DisplaySelectedFilter
          productStatusColor={productStatusColorMapping[LOW_STOCK]}
          productCount={lowStockProducts.length ?? 0}
          productStatus={LOW_STOCK}
          onClickCloseFilter={() => onClearFilter(LOW_STOCK)}
        />
      ) : (
        <Tooltip
          placement="top"
          title={LOW_STOCK}
          style={{ fontFamily: "AvertaStd-Semibold" }}
        >
          <div className="slab-filter-content">
            <div className="yellow-dot" />
            <div
              className="slab-filter-count"
              onClick={() => {
                if (lowStockProducts.length > 0) onClickSelectFilter(LOW_STOCK);
              }}
            >
              {lowStockProducts?.length ?? 0}
            </div>
          </div>
        </Tooltip>
      )}
      {selectedFilter?.includes(IN_STOCK) ? (
        <DisplaySelectedFilter
          productStatusColor={productStatusColorMapping[IN_STOCK]}
          productCount={inStockProducts.length ?? 0}
          productStatus={IN_STOCK}
          onClickCloseFilter={() => onClearFilter(IN_STOCK)}
        />
      ) : (
        <Tooltip
          placement="top"
          title={IN_STOCK}
          style={{ fontFamily: "AvertaStd-Semibold" }}
        >
          <div className="slab-filter-content">
            <div className="green-dot" />
            <div
              className="slab-filter-count"
              onClick={() => {
                if (inStockProducts.length > 0) onClickSelectFilter(IN_STOCK);
              }}
            >
              {" "}
              {inStockProducts?.length ?? 0}
            </div>
          </div>
        </Tooltip>
      )}
    </div>
  );
}

export default SlabPredictionFilters;
