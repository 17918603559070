import { Button, Col, Form, Input, message, Row } from 'antd';
import { Auth } from 'aws-amplify';
import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { checkPasswordErrors } from '../../Utils/PasswordUtils';
import './ResetPassword.scss';

class ResetPassword extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isPasswordChanged: false,
      validPassword: {
        length: null,
        upperCase: null,
        lowerCase: null,
        number: null,
        specialCharacter: null,
      },
      showAllPasswordErrors: null,
      passwordsConsistent: null,
    };
    this.setParams = this.setParams.bind(this);
    this.onChangePassword = this.onChangePassword.bind(this);
    this.resetPassword = this.resetPassword.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.validatePassword = this.validatePassword.bind(this);
    this.validateBothPasswords = this.validateBothPasswords.bind(this);
    this.handlePasswordBlur = this.handlePasswordBlur.bind(this);
  }

  componentDidMount() {
    this.setParams();
  }

  setParams() {
    const queryParamObj = new URLSearchParams(window.location.search);
    const code = queryParamObj.get('code');
    const username = queryParamObj.get('username');
    if (code && username) {
      this.setState({ code, username });
    } else {
      this.setState({ brokenUrl: true });
    }
  }

  onChangePassword() {
    const { newPassword, brokenUrl } = this.state;
    if (brokenUrl) return;

    if (newPassword) this.resetPassword(newPassword);
  }

  resetPassword(password) {
    const { username, code } = this.state;
    const { history } = this.props;
    if (!username || !code || !password) return;
    Auth.forgotPasswordSubmit(username, code, password)
      .then(() => {
        this.setState({ isPasswordChanged: true, updatingPassword: false });
        message.success('Password updated ');
        history.push('/');
      })
      .catch((err) => {
        let errMsg = 'Sorry, we are currently unable to handle your request.';
        if (err.code === 'ExpiredCodeException') {
          errMsg = 'The link to reset your password has expired. Please request a new link.';
        }
        this.setState({ updatingPassword: false });
        message.error(errMsg);
        history.push('/');
      });
  }

  handleChange(e) {
    const { name, value } = e.target;
    if (name) this.setState({ [name]: value });

    if (name === 'newPassword') {
      this.validatePassword(value);

      const { confirmPassword } = this.state;
      if (confirmPassword) this.validateBothPasswords(value, confirmPassword);
    }

    if (name === 'confirmPassword') {
      const { newPassword } = this.state;
      this.validateBothPasswords(newPassword, value);
    }
  }

  validatePassword(password) {
    const passwordErrors = checkPasswordErrors(password);
    this.setState({ validPassword: passwordErrors });
  }

  validateBothPasswords(newPassword, confirmPassword) {
    if (newPassword && confirmPassword) {
      if (newPassword === confirmPassword) {
        this.setState({ passwordsConsistent: true });
        // return true;
      } else {
        this.setState({ passwordsConsistent: false });
      }
    }
    return false;
  }

  handlePasswordBlur() {
    this.setState({ showAllPasswordErrors: true });
  }

  loadPasswordScreen() {
    const { newPassword, passwordsConsistent, updatingPassword } = this.state;
    const canContinue = newPassword && passwordsConsistent;
    return (
      <Row style={{ width: '100%' }}>
        <Col xs={1} sm={2} lg={7} md={2} xl={8} xxl={8} />
        <Col xs={22} sm={20} lg={10} md={20} xl={8} xxl={8}>
          <div className="forgot-password-wrapper">
            <div className="inputs-alignment-panel">
              <div className="forgot-password-email-label"> Enter New Password</div>
              {/* <div className="forgot-password-password-label">Password</div> */}
              <div className="forgot-password-input-field">
                <Form.Item formitemlayout="vertical" colon={false}>
                  <Input.Password
                    name="newPassword"
                    onChange={this.handleChange}
                    onBlur={this.handlePasswordBlur}
                    placeholder="New Password"
                  />
                </Form.Item>
                <Form.Item formitemlayout="vertical" colon={false}>
                  <Input.Password
                    onBlur={(e) => this.validateBothPasswords(newPassword, e.target.value)}
                    name="confirmPassword"
                    onChange={this.handleChange}
                    placeholder="Confirm password"
                  />
                </Form.Item>
              </div>
              <div className="password-matching-text">
                {passwordsConsistent !== null && !passwordsConsistent ? 'Password does not match.' : null}
              </div>
            </div>

            <div style={{ textAlign: 'center' }}>
              <Button
                loading={updatingPassword}
                disabled={!canContinue}
                onClick={() => {
                  this.setState({ updatingPassword: true });
                  this.onChangePassword();
                }}
                className="forgot-password-reset-btn"
                style={{ width: '76%' }}
              >
                {updatingPassword ? 'Updating' : 'Reset Password'}
              </Button>
            </div>
          </div>
        </Col>
        <Col xs={1} sm={2} lg={7} md={2} xl={8} xxl={8} />
      </Row>
    );
  }

  render() {
    const { brokenUrl } = this.state;
    if (brokenUrl) return <div>Broken Url</div>;

    return <div style={{ display: 'flex', justifyContent: 'center' }}>{this.loadPasswordScreen()}</div>;
  }
}
export default withRouter(ResetPassword);
