import _ from 'lodash';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import * as AuthenticationActions from '../../Actions/AuthenticationActions';
import LoginForm from '../../Components/LoginForm/LoginForm';
import * as AuthenticationReducer from '../../Reducers/AuthenticationReducer';
import './LoginContainer.scss';

const mapStateToProps = (state) => ({
  authLoginError: AuthenticationReducer.getAuthLoginError(state),
  authChangePasswordRequired: AuthenticationReducer.getAuthChangePasswordRequiredStatus(state),
  signInStatus: AuthenticationReducer.getSignInStatus(state),
});

const mapDispatchToProps = {
  logIn: AuthenticationActions.logIn,
};
class LoginContainer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      username: '',
      password: '',
      loading: false,
      loginError: '',
    };
    this.onSubmit = this.onSubmit.bind(this);
    this.onHandleChange = this.onHandleChange.bind(this);
    this.onForgotPassword = this.onForgotPassword.bind(this);
    this.onLoginError = this.onLoginError.bind(this);
  }

  componentDidUpdate(prevProps) {
    const { authLoginError, signInStatus } = this.props;
    if (!_.isEmpty(authLoginError) && authLoginError !== prevProps.authLoginError) {
      this.onLoginError(authLoginError);
    } else if (signInStatus && signInStatus !== prevProps.signInStatus) {
      this.props.changeState('signedIn');
    }
  }

  onLoginError(authLoginError) {
    this.setState({ loginError: authLoginError.message, loading: false });
  }

  onSubmit() {
    const { password, username, loading } = this.state;
    const { logIn } = this.props;
    this.setState({ loginError: '' });
    if (loading || !username || !password) {
      return;
    }
    this.setState({
      loading: true,
      username: username.trim().toLowerCase(),
    });
    logIn(username.trim().toLowerCase(), password);
  }

  onHandleChange(e) {
    this.setState({
      [e.target.name]: e.target.value,
    });
  }

  onForgotPassword() {
    this.props.changeState('forgotPassword');
  }

  render() {
    const { username, password, loading, loginError } = this.state;
    const { history } = this.props;

    return (
      <div className="login-container-wrapper">
        <LoginForm
          onHandleChange={this.onHandleChange}
          onSubmit={this.onSubmit}
          username={username}
          password={password}
          loading={loading}
          onForgotPassword={this.onForgotPassword}
          history={history}
          loginError={loginError}
        />
      </div>
    );
  }
}
export default connect(mapStateToProps, mapDispatchToProps)(LoginContainer);
