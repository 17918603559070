import { Auth } from 'aws-amplify';
import axios from 'axios';
import config from '../Config/Config.json';

const authorizedAPIClient = axios.create({
  baseURL: config.urls.baseAPIUrl,
});

const authorizedProdAPIClient = axios.create({
  baseURL: config.urls.baseAPIUrlProduction,
});

const axiosInterceptor = (configuration) => {
  return Auth.currentSession()
    .then((session) => {
      const { jwtToken: accessToken } = session.getAccessToken();
      const { headers } = configuration;
      headers['Authorization'] = `Bearer ${accessToken}`;
      return Promise.resolve(configuration);
    })
    .catch(() => Promise.resolve(configuration));
};

authorizedAPIClient.interceptors.request.use(axiosInterceptor);
authorizedProdAPIClient.interceptors.request.use(axiosInterceptor);

export { authorizedAPIClient, authorizedProdAPIClient };
