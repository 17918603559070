/* eslint-disable no-useless-computed-key */
import dayjs from 'dayjs';
import _ from 'lodash';
import uuid from 'uuid';

export const presetRanges = [30, 60, 90, 120, 150, 180];

export const getFilteredProductList = (predictionTable) => {
  if (_.isEmpty(predictionTable)) return [];
  let newProductList = [];
  for (const [key, value] of Object.entries(predictionTable)) {
    if (key !== 'ALL') newProductList.push({ label: key, value: key });
  }
  return newProductList;
};

export const getInStockProducts = (productList) => {
  if (!productList || productList.length === 0) return [];
  let inStockProducts = [];
  productList.forEach((product) => {
    if (product.Required > product.InStock) {
      const inStockPercentage = ((product.Required - product.InStock) / product.Required) * 100;
      if (inStockPercentage.toFixed(0) < 30) {
        inStockProducts.push(product);
      }
    }
  });

  return inStockProducts;
};

export const getLowStockProducts = (productList) => {
  if (!productList || productList.length === 0) return [];
  let lowStockProduct = [];
  productList.forEach((product) => {
    if (product.Required > product.InStock) {
      const lowStockPercentage = ((product.Required - product.InStock) / product.Required) * 100;
      if (lowStockPercentage.toFixed(0) < 95 && lowStockPercentage.toFixed(0) > 30) {
        lowStockProduct.push(product);
      }
    }
  });
  return lowStockProduct;
};

export const getOutOfStockProducts = (productList) => {
  if (!productList || productList.length === 0) return [];
  let outOfStock = [];
  productList.forEach((product) => {
    if (product.Required > product.InStock) {
      const outOfStockPercentage = ((product.Required - product.InStock) / product.Required) * 100;
      if (outOfStockPercentage.toFixed(0) >= 95) {
        outOfStock.push(product);
      }
    }
  });
  return outOfStock;
};

export const getOverStockedProducts = (productList) => {
  if (!productList || productList.length === 0) return [];
  let overStocked = [];
  productList.forEach((product) => {
    if (product.InStock > product.Required) {
      overStocked.push(product);
    }
  });
  return overStocked;
};

export const OUT_OF_STOCK = 'Out of Stock';
export const IN_STOCK = 'In Stock';
export const OVER_STOCKED = 'Over Stocked';
export const LOW_STOCK = 'Low Stock';

export const productStatusColorMapping = {
  [IN_STOCK]: '#13C26B',
  [OVER_STOCKED]: '#F5222D',
  [LOW_STOCK]: '#FAAD14',
};

const getProductStatus = ({ instock, required }) => {
  instock = parseInt(instock, 10);
  required = parseInt(required, 10);
  if (instock > required) return OVER_STOCKED;
  else if (instock <= required) {
    const instockAndRequiredGap = required - instock;
    let instockAndRequiredPercentage = 0;
    if (instockAndRequiredGap > 0) {
      if (required && required !== 0) {
        instockAndRequiredPercentage = (instockAndRequiredGap / required) * 100;
        if (instockAndRequiredPercentage <= 5) {
          return IN_STOCK;
        } else {
          return LOW_STOCK;
        }
      }
    }
  }
};

export const _enrichPredictionTables = (rawPredictionTable) => {
  const enriched90DaysPredictionTable = getAnyDaysPredictionTable(rawPredictionTable, 90);
  const enriched180DaysPredictionTable = getAnyDaysPredictionTable(rawPredictionTable, 180);
  const enriched270DaysPredictionTable = getAnyDaysPredictionTable(rawPredictionTable, 270);
  const enriched360DaysPredictionTable = getAnyDaysPredictionTable(rawPredictionTable, 365);
  const allDataPrediction = getAllDataPredictionTable(rawPredictionTable);
  return {
    enriched90DaysTable: enriched90DaysPredictionTable,
    enriched180DaysTable: enriched180DaysPredictionTable,
    enriched270DaysTable: enriched270DaysPredictionTable,
    enriched360DaysTable: enriched360DaysPredictionTable,
    allDataPrediction,
  };
};

const getAnyDaysPredictionTable = (predictionTable, noOfDays) => {
  if (_.isEmpty(predictionTable)) return;
  let enrichedPredictionTable = [];
  let keyCount = 1;

  for (const [predictionTableKey, predictionTableValue] of Object.entries(predictionTable)) {
    for (const [key, value] of Object.entries(predictionTableValue)) {
      if (key === `pred_${noOfDays}_Days` && predictionTableKey !== 'ALL') {
        const confidence = value?.confidence * 100;

        let qtyToOrder = 0;

        qtyToOrder = value.purchase - value.available;

        enrichedPredictionTable.push({
          key: uuid.v4(),
          status: getProductStatus({
            instock: value?.available?.toFixed(0) ?? 0,
            required: value?.purchase?.toFixed(0) ?? 0,
          }),
          productNumber: keyCount,
          product: [
            predictionTableKey,
            getProductStatus({
              instock: value?.available?.toFixed(0) ?? 0,
              required: value?.purchase?.toFixed(0) ?? 0,
            }),
          ],
          quantity: isNaN(qtyToOrder) || qtyToOrder < 0 ? 0 : Math.floor(qtyToOrder)?.toLocaleString('en'),
          // quantity: 100,
          absoluteQuantity: isNaN(qtyToOrder) || qtyToOrder < 0 ? 0 : Math.floor(qtyToOrder),
          confidence: confidence?.toFixed(0),
          prediction: [
            Math.floor(value?.available).toLocaleString('en'),
            Math.floor(value?.purchase).toLocaleString('en'),
            value?.available?.toFixed(0) ?? 0,
            value?.purchase?.toFixed(0) ?? 0,
            getProductStatus({
              instock: value?.available?.toFixed(0) ?? 0,
              required: value?.purchase?.toFixed(0) ?? 0,
            }),
          ],
        });
        keyCount = keyCount + 1;
      }
    }
  }
  return enrichedPredictionTable;
};

const getAllDataPredictionTable = (predictionTable) => {
  if (_.isEmpty(predictionTable)) return;
  const all90DaysPredictionData = {};
  const all180DaysPredictionData = {};
  const all270DaysPredictionData = {};
  const all365DaysPredictionData = {};

  for (const [predictionTableKey, predictionTableValue] of Object.entries(predictionTable)) {
    for (const [key, value] of Object.entries(predictionTableValue)) {
      if (predictionTableKey === 'ALL') {
        if (key === 'pred_90_Days') {
          let qtyToOrder = 0;
          const confidence = value?.confidence * 100;
          if (value?.purchase && value?.available) {
            qtyToOrder = value.purchase - value.available;
          }
          all90DaysPredictionData['confidence'] = confidence.toFixed(0);
          all90DaysPredictionData['quantity'] = qtyToOrder < 0 ? 0 : Math.floor(qtyToOrder)?.toLocaleString('en');
          all90DaysPredictionData['prediction'] = [
            Math.floor(value?.available)?.toLocaleString('en'),
            Math.floor(value?.purchase)?.toLocaleString('en'),
            value?.available?.toFixed(0) ?? 0,
            value?.purchase?.toFixed(0) ?? 0,
            getProductStatus({
              instock: value?.available?.toFixed(0) ?? 0,
              required: value?.purchase?.toFixed(0) ?? 0,
            }),
          ];
          all90DaysPredictionData['status'] = getProductStatus({
            instock: value?.available?.toFixed(0) ?? 0,
            required: value?.purchase?.toFixed(0) ?? 0,
          });
        } else if (key === 'pred_180_Days') {
          let qtyToOrder = 0;
          const confidence = value?.confidence * 100;
          if (value?.purchase && value?.available) {
            qtyToOrder = value.purchase - value.available;
          }
          all180DaysPredictionData['confidence'] = confidence.toFixed(0);
          all180DaysPredictionData['quantity'] = qtyToOrder < 0 ? 0 : Math.floor(qtyToOrder)?.toLocaleString('en');
          all180DaysPredictionData['prediction'] = [
            Math.floor(value?.available)?.toLocaleString('en'),
            Math.floor(value?.purchase)?.toLocaleString('en'),
            value?.available?.toFixed(0) ?? 0,
            value?.purchase?.toFixed(0) ?? 0,
            getProductStatus({
              instock: value?.available?.toFixed(0) ?? 0,
              required: value?.purchase?.toFixed(0) ?? 0,
            }),
          ];
          all180DaysPredictionData['status'] = getProductStatus({
            instock: value?.available?.toFixed(0) ?? 0,
            required: value?.purchase?.toFixed(0) ?? 0,
          });
        } else if (key === 'pred_270_Days') {
          let qtyToOrder = 0;
          if (value?.purchase && value?.available) {
            qtyToOrder = value.purchase - value.available;
          }
          const confidence = value?.confidence * 100;
          all270DaysPredictionData['confidence'] = confidence.toFixed(0) ?? 0;
          all270DaysPredictionData['quantity'] = qtyToOrder < 0 ? 0 : Math.floor(qtyToOrder)?.toLocaleString('en');
          all270DaysPredictionData['prediction'] = [
            Math.floor(value?.available)?.toLocaleString('en'),
            Math.floor(value?.purchase)?.toLocaleString('en'),
            value?.available?.toFixed(0) ?? 0,
            value?.purchase?.toFixed(0) ?? 0,
            getProductStatus({
              instock: value?.available?.toFixed(0) ?? 0,
              required: value?.purchase?.toFixed(0) ?? 0,
            }),
          ];
          all270DaysPredictionData['status'] = getProductStatus({
            instock: value?.available?.toFixed(0) ?? 0,
            required: value?.purchase?.toFixed(0) ?? 0,
          });
        } else {
          let qtyToOrder = 0;
          if (value?.purchase && value?.available) {
            qtyToOrder = value.purchase - value.available;
          }
          const confidence = value?.confidence * 100;
          all365DaysPredictionData['confidence'] = confidence.toFixed(0);
          all365DaysPredictionData['quantity'] = qtyToOrder < 0 ? 0 : Math.floor(qtyToOrder)?.toLocaleString('en');
          all365DaysPredictionData['prediction'] = [
            Math.floor(value?.available)?.toLocaleString('en'),
            Math.floor(value?.purchase)?.toLocaleString('en'),
            value?.available?.toFixed(0) ?? 0,
            value?.purchase?.toFixed(0) ?? 0,
            getProductStatus({
              instock: value?.available?.toFixed(0) ?? 0,
              required: value?.purchase?.toFixed(0) ?? 0,
            }),
          ];
          all365DaysPredictionData['status'] = getProductStatus({
            instock: value?.available?.toFixed(0) ?? 0,
            required: value?.purchase?.toFixed(0) ?? 0,
          });
        }
      }
    }
  }
  return {
    all90DaysPredictionData,
    all180DaysPredictionData,
    all270DaysPredictionData,
    all365DaysPredictionData,
  };
};

export const getOutOfStockProductsList = (productList) => {
  if (!productList || productList.length === 0) return [];
  return productList.filter((product) => product.status === OUT_OF_STOCK);
};

export const getInStockProductsList = (productList) => {
  if (!productList || productList.length === 0) return [];
  return productList.filter((product) => product.status === IN_STOCK);
};

export const getOverStockedProductsList = (productList) => {
  if (!productList || productList.length === 0) return [];
  return productList.filter((product) => product.status === OVER_STOCKED);
};

export const getLowStockProductsList = (productList) => {
  if (!productList || productList.length === 0) return [];
  return productList.filter((product) => product.status === LOW_STOCK);
};

export const getPredictionDays = (startDate, endDate) => {
  const oneDay = 1000 * 60 * 60 * 24;
  const clonedStartDate = new Date(startDate);
  const clonedEndDate = new Date(endDate);

  const start = Date.UTC(clonedStartDate.getFullYear(), clonedStartDate.getMonth(), clonedStartDate.getDate());
  const end = Date.UTC(clonedEndDate.getFullYear(), clonedEndDate.getMonth(), clonedEndDate.getDate());

  const differenceInDays = (end - start) / oneDay;
  return getDifferenceInDays(differenceInDays);
};

const getDifferenceInDays = (differenceInDays) => {
  if (differenceInDays <= 0) return 0;
  else if (differenceInDays >= 30 && differenceInDays < 60) return 30;
  else if (differenceInDays >= 60 && differenceInDays < 90) return 60;
  else if (differenceInDays >= 90 && differenceInDays < 120) return 90;
  else if (differenceInDays >= 120 && differenceInDays < 150) return 120;
  else if (differenceInDays >= 150 && differenceInDays < 180) return 150;
  else if (differenceInDays >= 180 && differenceInDays < 210) return 180;
  else if (differenceInDays >= 210 && differenceInDays < 240) return 210;
  else if (differenceInDays >= 240 && differenceInDays < 270) return 240;
  else if (differenceInDays >= 270 && differenceInDays < 300) return 270;
  else if (differenceInDays >= 300 && differenceInDays < 330) return 300;
  else if (differenceInDays >= 330 && differenceInDays < 360) return 330;
  else if (differenceInDays >= 360 && differenceInDays < 390) return 365;
  else return differenceInDays;
};

export const differenceInUploadedDays = (uploadedDate) => {
  const lastUploadedDateString = dayjs(uploadedDate, 'YYYY-MM-DD').format('MM/DD/YYYY');
  const todaysDate = dayjs().format('MM/DD/YYYY');
  const oneDay = 1000 * 60 * 60 * 24;

  const clonedStartDate = new Date(lastUploadedDateString);
  const clonedEndDate = new Date(todaysDate);

  const differenceInTime = clonedEndDate.getTime() - clonedStartDate.getTime();

  return differenceInTime / oneDay;
};

export const getLabelForDateDifferenceFromToday = (noOfDays) => {
  if (isNaN(noOfDays)) return '';
  if (noOfDays === 0) return 'Updated today';
  if (noOfDays <= 2) return 'Updated a day ago';
  return `Updated ${noOfDays} days ago`;
};

export const differenceOfDays = (uploadedDate) => {
  const lastUploadedDateString = dayjs(uploadedDate, 'DD-MM-YYYY').format('DD-MM-YYYY');
  const todaysDate = dayjs().format('DD-MM-YYYY');
  const oneDay = 1000 * 60 * 60 * 24;

  const clonedStartDate = new Date(lastUploadedDateString);
  const clonedEndDate = new Date(todaysDate);

  const differenceInTime = clonedEndDate.getTime() - clonedStartDate.getTime();

  return differenceInTime / oneDay;
};

export const getInitialDateRange = () => {
  return [
    { label: 'Next 90 Days', value: [dayjs(), dayjs().add(90, 'd')] },
    { label: 'Next 180 Days', value: [dayjs(), dayjs().add(180, 'd')] },
    { label: 'Next 270 Days', value: [dayjs(), dayjs().add(270, 'd')] },
    { label: 'Next 365 Days', value: [dayjs(), dayjs().add(365, 'd')] },
  ];
};

export const setRangePickerInitialValueWithDiff = (form, noOfDaysDiff, setStartDateValue) => {
  form.setFieldsValue({
    ['startDate']: dayjs()
      .add(-1 * noOfDaysDiff, 'd')
      .format('DD-MMMM-YYYY')
      .toString(),
  });
  form.setFieldsValue({
    ['endDate']: dayjs()
      .add(-1 * noOfDaysDiff + 90, 'd')
      .format('DD-MMMM-YYYY')
      .toString(),
  });
  form.setFieldsValue({
    ['dateRange']: [dayjs().add(-1 * noOfDaysDiff, 'd'), dayjs().add(-1 * noOfDaysDiff + 90, 'd')],
  });
};

export const setRangePickerInitialValueWithoutDiff = (form) => {
  form.setFieldsValue({
    ['startDate']: dayjs().format('DD-MMMM-YYYY').toString(),
  });
  form.setFieldsValue({
    ['endDate']: dayjs().add(90, 'd').format('DD-MMMM-YYYY').toString(),
  });
  form.setFieldsValue({
    ['dateRange']: [dayjs(), dayjs().add(90, 'd')],
  });
};

export const setRangePresentInitialValueWithDiff = (noOfDaysDiff, setDateRangePreset) => {
  setDateRangePreset([
    {
      label: 'Next 90 Days',
      value: [dayjs().add(-1 * noOfDaysDiff, 'd'), dayjs().add(-1 * noOfDaysDiff + 90, 'd')],
    },
    {
      label: 'Next 180 Days',
      value: [dayjs().add(-1 * noOfDaysDiff, 'd'), dayjs().add(-1 * noOfDaysDiff + 180, 'd')],
    },
    {
      label: 'Next 270 Days',
      value: [dayjs().add(-1 * noOfDaysDiff, 'd'), dayjs().add(-1 * noOfDaysDiff + 270, 'd')],
    },
    {
      label: 'Next 365 Days',
      value: [dayjs().add(-1 * noOfDaysDiff, 'd'), dayjs().add(-1 * noOfDaysDiff + 365, 'd')],
    },
  ]);
};

export const setRangePresentInitialValueWithoutDiff = (setDateRangePreset) => {
  setDateRangePreset([
    {
      label: 'Next 90 Days',
      value: [dayjs(), dayjs().add(90, 'd')],
    },
    {
      label: 'Next 180 Days',
      value: [dayjs(), dayjs().add(180, 'd')],
    },
    {
      label: 'Next 270 Days',
      value: [dayjs(), dayjs().add(270, 'd')],
    },
    {
      label: 'Next 365 Days',
      value: [dayjs(), dayjs().add(365, 'd')],
    },
  ]);
};

export const setDateRangePresetFromStartDate = (startDate, setDateRangePreset) => {
  if (startDate && startDate.trim().length > 0) {
    const start = dayjs(startDate, 'DD-MMMM-YYYY');
    const end90 = start.add(90, 'd');
    const end180 = start.add(180, 'd');
    const end270 = start.add(270, 'd');
    const end365 = start.add(365, 'd');

    const datePresets = [
      { label: 'Next 90 Days', value: [start, end90] },
      { label: 'Next 180 Days', value: [start, end180] },
      { label: 'Next 270 Days', value: [start, end270] },
      { label: 'Next 365 Days', value: [start, end365] },
    ];

    const differenceInDays = start.diff(end365, 'd');
    if (differenceInDays > 365) {
      setDateRangePreset([{ label: 'Next 365 Days', value: [start, end365] }]);
    } else {
      setDateRangePreset(datePresets);
    }
  }
  return null;
};
