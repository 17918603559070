import { setApiStatus } from "../ApiStatusActions";
export const SET_SALES_SHEET_DATA = "SET_SALES_SHEET_DATA";

export const SET_ERROR_MESSAGE_NOTIFICATION_AND_STATUS = "SET_ERROR_MESSAGE_NOTIFICATION_AND_STATUS";

export const SET_SALES_FILE_DETAILS_BY_LOCATION =
  "SET_SALES_FILE_DETAILS_BY_LOCATION";

export const SET_REFRESH_NOTIFICATION_VISBILITY =
  "SET_REFRESH_NOTIFICATION_VISBILITY";

export function setSalesSheetData(data) {
  return { type: SET_SALES_SHEET_DATA, payload: data };
}

export function setSalesSheetApiStatus(status) {
  const apiName = "SalesSheetApiStatus";
  return setApiStatus({ apiName, status });
}
export function setUploadSalesSheetApiStatus(status) {
  const apiName = "UploadSalesSheetApiStatus";
  return setApiStatus({ apiName, status });
}

export const setPollingSalesFileApiStatus = (status) => {
  const apiName = "SalesFilePollingApiStatus";
  return setApiStatus({ apiName, status });
};

export const setSalesFileProcessStatus = (status) => {
  const apiName = "SalesFileProcessStatus";
  return setApiStatus({ apiName, status });
};

export const setSalesFileDetailsByLocation = (data) => {
  return {
    type: SET_SALES_FILE_DETAILS_BY_LOCATION,
    payload: data,
  };
};

export const setErrorMessageNotificationAndStatus = (data) => {
  return {
    type: SET_ERROR_MESSAGE_NOTIFICATION_AND_STATUS, 
    payload: data,
  }
}

export const setRefreshNotificationVisibility = (status) => {
  return {
    type: SET_REFRESH_NOTIFICATION_VISBILITY,
    payload: status,
  };
};
