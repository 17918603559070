import { InfoCircleFilled } from '@ant-design/icons';
import { Col, Row, Skeleton, Tooltip } from 'antd';
import dayjs from 'dayjs';
import React from 'react';
import { FileUploader } from 'react-drag-drop-files';
import { useDispatch, useSelector } from 'react-redux';
import { uploadSalesSheetData } from '../../Actions/SalesSheetActions';
import { getApiStatus } from '../../Reducers/ApiStatusReducer';
import { getLatestSalesSheetData, getSalesFileDetailsByLocation } from '../../Reducers/SalesSheetReducer';
import { differenceInUploadedDays, getLabelForDateDifferenceFromToday } from '../../Utils/SlabPredictionFormUtils';
import FileDownloadButton from '../Common/FileDownloadButton';
import './UploadedSaleSheet.scss';

const fileTypes = ['XLSX'];

function UploadedSaleSheet(props) {
  const { fileProcessingDispay, fileUploadingDispay, currentBranch } = props;

  const dispatch = useDispatch();

  const uploadSalesSheetApiStatus = useSelector((state) => getApiStatus(state, 'UploadSalesSheetApiStatus'));

  const uploadedFileProcessStatus = useSelector((state) => getApiStatus(state, 'SalesFileProcessStatus'));

  const getLatestSalesSheetApiStatus = useSelector((state) => getApiStatus(state, 'SalesSheetApiStatus'));

  const { salesinfo: lastestSalesData } = useSelector((state) => getLatestSalesSheetData(state));

  const salesFileDetailsByLocation = useSelector((state) => getSalesFileDetailsByLocation(state)) ?? {};

  const lastUpdatedInDays = differenceInUploadedDays(lastestSalesData?.last_uploaded_date);

  const uploadSalesSheet = async (file) => {
    const salesData = {
      file_name: file[0]?.name,
      xlsx_data: '',
      branch: currentBranch,
      file_type: 'Sales',
    };
    await new Promise((resolve) => {
      const reader = new FileReader();
      reader.readAsDataURL(file[0]);
      reader.onloadend = () => {
        salesData.xlsx_data = reader?.result?.replace(/^data:.+;base64,/, '');
        resolve();
      };
    });
    dispatch(uploadSalesSheetData(salesData));
  };

  const uploadNewFileDisplay =
    uploadSalesSheetApiStatus === 'INPROGRESS' || uploadedFileProcessStatus === 'PENDING' ? null : (
      <div className="uploaded-sales-sheet-right-content">
        <div>
          <FileUploader multiple={true} handleChange={uploadSalesSheet} name="file" types={fileTypes}>
            <div className="uploaded-sales-sheet-upload-btn">Upload New</div>
          </FileUploader>
        </div>
      </div>
    );

  const fileDownloadButton = <FileDownloadButton downloadFileName="Sales_demo" fileName="Sales_demo.xlsx" />;

  const uploadSheetWrapper = (
    <div>
      <div className="uploaded-sales-sheet-header">
        <div className="label-and-info-section">
          <div className="label-name">Sales Sheet {fileDownloadButton}</div>
        </div>
        <FileUploader multiple={true} handleChange={uploadSalesSheet} name="file" types={fileTypes}>
          <div className="uploaded-sales-sheet-content">
            <div className="uploaded-sales-sheet-left-content uploadFile">
              <img
                src={`${process.env.PUBLIC_URL}/static/Images/xlsx_icon.svg`}
                alt="arya logo"
                width={32}
                height={32}
              />
              {uploadSalesSheetApiStatus !== 'INPROGRESS' && (
                <span className="selectFileContent">{'Select a sales sheet file to upload'}</span>
              )}
              <div className="uploaded-sales-sheet-right-content">
                <div>{uploadSalesSheetApiStatus === 'INPROGRESS' && fileUploadingDispay}</div>
              </div>
            </div>
          </div>
        </FileUploader>
        <div className="supportedFormInfo">Supported: xlsx</div>
      </div>
    </div>
  );

  const displayFileName =
    (lastestSalesData?.file_name || salesFileDetailsByLocation[`${currentBranch}`]?.file_name) ?? 'Sales sheet.xlsx';

  const uploadedSheetWrapper = (
    <div className="uploaded-sales-sheet-header">
      <div className="label-and-info-section">
        <div className="label-name">Sales Sheet {fileDownloadButton}</div>
        <div className="uploaded-sales-sheet-last-updated">{getLabelForDateDifferenceFromToday(lastUpdatedInDays)}</div>
      </div>

      <div className="uploaded-sales-sheet-content">
        <Row style={{ width: '100%' }} gutter={[0, 2]}>
          <Col xs={24} sm={6} lg={16} md={12} xl={15} xxl={16}>
            <div className="uploaded-sales-sheet-left-content">
              <img
                src={`${process.env.PUBLIC_URL}/static/Images/xlsx_icon.svg`}
                alt="arya logo"
                width={32}
                height={32}
              />
              <div
                style={{
                  paddingLeft: '14px',
                  color: '#1F2730',
                  fontWeight: 500,
                  wordBreak: 'break-all',
                  overflow: 'hidden',
                  textOverflow: 'ellipsis',
                  whiteSpace: 'nowrap',
                  width: '130px',
                }}
              >
                {' '}
                <Tooltip title={displayFileName}>{`${displayFileName?.substring(0, 45)}...`}</Tooltip>
              </div>
            </div>
          </Col>
          <Col
            xs={24}
            sm={18}
            lg={8}
            md={12}
            xl={9}
            xxl={8}
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'flex-end',
            }}
          >
            {uploadSalesSheetApiStatus === 'INPROGRESS' && fileUploadingDispay}
            {uploadedFileProcessStatus === 'PENDING' && fileProcessingDispay}
            {uploadNewFileDisplay}
          </Col>
        </Row>
      </div>
      <div
        style={{
          fontSize: '12px',
          color: '#51575E',
          display: 'flex',
          gap: '8px',
          paddingTop: '12px',
        }}
      >
        {lastestSalesData?.actual_date ? (
          <>
            <div>
              <InfoCircleFilled style={{ color: '#1890FF' }} />
            </div>
            Latest Product sale date is {dayjs(lastestSalesData?.actual_date).format('DD/MM/YYYY')}
          </>
        ) : (
          <br />
        )}
      </div>
    </div>
  );

  const displaySalesComponent =
    lastestSalesData?.file_name || salesFileDetailsByLocation[`${currentBranch}`]?.file_name
      ? uploadedSheetWrapper
      : uploadSheetWrapper;

  return (
    <Skeleton loading={getLatestSalesSheetApiStatus === 'INPROGRESS'}>
      <div className="uploaded-sales-sheet-wrapper">{displaySalesComponent}</div>
    </Skeleton>
  );
}

export default UploadedSaleSheet;
