const SET_API_STATUS = 'SET_API_STATUS';

function setApiStatus({ apiName, status }) {
  return {
    payload: { apiName, status },
    type: SET_API_STATUS,
  };
}

export { SET_API_STATUS, setApiStatus };
