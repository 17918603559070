import { DownOutlined, UserOutlined } from '@ant-design/icons';
import { Avatar, Button, Dropdown, Popover, Space } from 'antd';
import _ from 'lodash';
import moment from 'moment';
import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { _setCurrentBranch } from '../../Actions/BranchActions';
import SettingsMenu from '../../Components/SettingsMenu/SettingsMenu';
import config from '../../Config/Config.json';
import { getApiStatus } from '../../Reducers/ApiStatusReducer';
import { getUserEmail } from '../../Reducers/AuthenticationReducer';
import './Navbar.scss';

const defaultLocation = _.get(config, 'locations[0].label');

function Navbar() {
  const [visiblePopover, setVisiblePopover] = useState(false);
  const [currentSelectedBranch, setCurrentSelectedBranch] = useState(defaultLocation);
  const dispatch = useDispatch();

  // const fetchedBranchesList = useSelector((state) => getFetchedBranches(state));
  const userEmail = useSelector((state) => getUserEmail(state));
  const currentDate = moment.utc().local().format('YYYY-MMM-DD');
  const year = currentDate?.slice(0, 4);
  const month = currentDate?.slice(5, 8);
  const date = currentDate?.slice(9, 11);
  let currentDay;
  const getCurrentDay = (num) => {
    switch (num) {
      case 0:
        currentDay = 'Sunday';
        break;
      case 1:
        currentDay = 'Monday';
        break;
      case 2:
        currentDay = 'Tuesday';
        break;
      case 3:
        currentDay = 'Wednesday';
        break;
      case 4:
        currentDay = 'Thursday';
        break;
      case 5:
        currentDay = 'Friday';
        break;
      case 6:
        currentDay = 'Saturday';
        break;
      default:
        currentDay = null;
        break;
    }
  };
  getCurrentDay(moment.utc().local().day());

  const handlePopoversVisibility = (visible) => {
    setVisiblePopover(visible);
  };

  const handleMenuClick = (value) => {
    setCurrentSelectedBranch(value.key);
    dispatch(_setCurrentBranch(value.key));
  };

  const items = config.locations;

  const uploadSalesSheetApiStatus = useSelector((state) => getApiStatus(state, 'UploadSalesSheetApiStatus'));

  const uploadInventorySheetApiStatus = useSelector((state) => getApiStatus(state, 'UploadInventorySheetApiStatus'));
  const uploadInventoryTransitSheetApiStatus = useSelector((state) =>
    getApiStatus(state, 'UploadInventoryTransitSheetApiStatus')
  );

  const uploadedFileProcessStatus = useSelector((state) => getApiStatus(state, 'SalesFileProcessStatus'));

  const salesFilePollingApiStatus = useSelector((state) => getApiStatus(state, 'SalesFilePollingApiStatus'));

  const isDropdownDisabled =
    [
      uploadSalesSheetApiStatus,
      uploadInventorySheetApiStatus,
      uploadInventoryTransitSheetApiStatus,
      salesFilePollingApiStatus,
    ].includes('INPROGRESS') || uploadedFileProcessStatus === 'PENDING';

  const menuProps = {
    items,
    onClick: handleMenuClick,
  };
  return (
    <div className="navbar-wrapper">
      <div className="navbar-content">
        <div className="navbar-content-wrapper">
          <div className="navbar-app-name">Slab Predictions App</div>
          <Dropdown disabled={isDropdownDisabled} menu={menuProps}>
            <Button>
              <Space className="navbar-branch-dropdown">
                {currentSelectedBranch}
                <DownOutlined />
              </Space>
            </Button>
          </Dropdown>
        </div>
        <div className="navbar-date">
          {date} {month} {year}, {currentDay}
        </div>
      </div>
      <div className="navbar-profile-notification">
        <Popover
          content={<SettingsMenu email={userEmail} />}
          trigger="click"
          getPopupContainer={(trigger) => trigger.parentNode}
          open={visiblePopover}
          onOpenChange={(visible) => handlePopoversVisibility(visible)}
          placement="bottomRight"
        >
          <Avatar icon={<UserOutlined />} />
        </Popover>
      </div>
    </div>
  );
}

export default Navbar;
