import React from 'react';
import Navbar from '../Navbar/Navbar';
import SlabPredictionContainer from '../SlabPredictionContainer/SlabPredictionContainer';

function LandingPageContainer() {
  return (
    <>
      <Navbar />
      <SlabPredictionContainer />
    </>
  );
}

export default LandingPageContainer;
