import { withAuthenticator } from 'aws-amplify-react';
import React from 'react';
import ForgotPasswordContainer from './Containers/ForgotPasswordContainer/ForgotPasswordContainer';
import Routes from './Routes';
import UnauthorizedRoutes from './UnauthorizedRoutes';

function App() {
    return <Routes />;
}

export default withAuthenticator(App, false, [
    <UnauthorizedRoutes />,
    <ForgotPasswordContainer />,
]);
