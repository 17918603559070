import { authorizedAPIClient } from './BaseRepository';

const getLastestFileUploadInfo = (data) => {
  return authorizedAPIClient.post(`/_uploadinfo`, data);
};

const uploadSalesSheet = (uploadedFileData) => {
  return authorizedAPIClient.post(`/_upload`, JSON.stringify(uploadedFileData), {
    timeout: 30000,
  });
};

const getFileProcessDataById = (payload) => {
  const headers = {
    'Content-Type': 'application/json',
  };

  // return new Promise((res) => {
  //   res({
  //     data: {
  //       invocation_status: 'FAILED',
  //       invocation_id: 'a026cd8a-4131-4660-98d9-e51720d40832',
  //     },
  //   });
  // });

  return authorizedAPIClient.post('/_status', payload, {
    headers,
  });
};

export { getLastestFileUploadInfo, uploadSalesSheet, getFileProcessDataById };
