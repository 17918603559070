import _ from 'lodash';
import { getFileProcessDataById, getLastestFileUploadInfo, uploadSalesSheet } from '../Repository/SalesSheetRepository';
import * as BranchActionCreator from './ActionCreators/BranchActionCreator';
import * as InventoryActionCreators from './ActionCreators/InventorySheetActionCreator';
import * as ActionCreators from './ActionCreators/SalesSheetActionCreator';
import { sendEmail } from './ApplicationActions';

export function getLastestFileUploadedData(data) {
  return async (dispatch) => {
    try {
      dispatch(ActionCreators.setSalesSheetApiStatus('INPROGRESS'));
      const latestSalesSheetApiResponse = await getLastestFileUploadInfo(data);
      const latestSalesSheetData = latestSalesSheetApiResponse?.data;
      dispatch(ActionCreators.setSalesSheetData(latestSalesSheetData));
      dispatch(BranchActionCreator.setSalesSheetDataByBranch(latestSalesSheetData));
      dispatch(ActionCreators.setSalesSheetApiStatus('COMPLETED'));
    } catch (error) {
      dispatch(
        ActionCreators.setErrorMessageNotificationAndStatus({
          message: 'Unable to fetch the results, please try again',
          status: true,
        })
      );
      dispatch(ActionCreators.setSalesSheetApiStatus('FAILED'));
    }
  };
}
export function uploadSalesSheetData(formData) {
  return async (dispatch) => {
    try {
      dispatch(ActionCreators.setUploadSalesSheetApiStatus('INPROGRESS'));
      const response = await uploadSalesSheet(formData);
      const { invocation_id } = response.data;
      if (invocation_id) {
        dispatch(
          ActionCreators.setSalesFileDetailsByLocation({
            [`${formData.branch}`]: { ...response.data, ...formData },
          })
        );
        dispatch(ActionCreators.setUploadSalesSheetApiStatus('COMPLETED'));
        dispatch(getSalesPollingDataById(invocation_id));
      }
    } catch (error) {
      dispatch(
        ActionCreators.setErrorMessageNotificationAndStatus({
          message: 'Oops! An error occurred while uploading the sales sheet, please try again',
          status: true,
        })
      );
      dispatch(ActionCreators.setUploadSalesSheetApiStatus('FAILED'));
    }
  };
}

export const getSalesPollingDataById = (invocation_id) => {
  return async (dispatch) => {
    dispatch(ActionCreators.setPollingSalesFileApiStatus('INPROGRESS'));
    dispatch(ActionCreators.setSalesFileProcessStatus('PENDING'));
    const pollInterval = 30000;
    const maxPollDuration = 300000;
    let elapsedTime = 0;

    const makeApiCall = async () => {
      try {
        const response = await getFileProcessDataById({
          invocation_id,
        });
        dispatch(ActionCreators.setPollingSalesFileApiStatus('COMPLETED'));

        const { invocation_status } = response?.data;
        switch (invocation_status?.toUpperCase()) {
          case 'INPROCESS': {
            dispatch(ActionCreators.setSalesFileProcessStatus('PENDING'));
            elapsedTime += pollInterval;
            if (elapsedTime < maxPollDuration) {
              setTimeout(makeApiCall, pollInterval);
              break;
            } else {
              dispatch(ActionCreators.setSalesFileProcessStatus('FAILED'));
              dispatch(
                ActionCreators.setErrorMessageNotificationAndStatus({
                  message: 'Something went weong, Please try again after sometime',
                  status: true,
                })
              );
              return;
            }
          }
          case 'SUCCESS': {
            dispatch(ActionCreators.setSalesFileProcessStatus('COMPLETED'));
            dispatch(ActionCreators.setRefreshNotificationVisibility(true));
            return;
          }
          case 'FAILED': {
            dispatch(sendEmail({ file_type: 'salessheet' }, true));
            // dispatch(ActionCreators.setSalesFileProcessStatus('FAILED'));
            return;
          }
          default:
            break;
        }
      } catch (error) {
        dispatch(
          ActionCreators.setErrorMessageNotificationAndStatus({
            message: 'Error occured while processing the file, please try again',
            status: true,
          })
        );
        dispatch(ActionCreators.setPollingSalesFileApiStatus('FAILED'));
        dispatch(ActionCreators.setSalesFileProcessStatus('FAILED'));
        return;
      }
    };

    setTimeout(makeApiCall, 30000);
  };
};

export function uploadInventorySheetData(data, type) {
  return async (dispatch) => {
    try {
      if (type === 'inventory') {
        dispatch(InventoryActionCreators.setUploadInventorySheetApiStatus('INPROGRESS'));
      } else {
        dispatch(InventoryActionCreators.setUploadInventoryTransitSheetApiStatus('INPROGRESS'));
      }
      const res = await uploadSalesSheet({
        ...data,
      });
      dispatch(
        InventoryActionCreators.setInventoryFileDetailsByLocation({
          [`${data?.branch}`]: { ...data, ...res.data },
        })
      );
      if (type === 'inventory') {
        dispatch(InventoryActionCreators.setUploadInventorySheetApiStatus('COMPLETED'));
      } else {
        dispatch(InventoryActionCreators.setUploadInventoryTransitSheetApiStatus('COMPLETED'));
      }
      dispatch(ActionCreators.setRefreshNotificationVisibility(true));
    } catch (error) {
      // dispatch(sendEmail(data));
      const errFromServer = _.get(error, 'response.data', undefined);

      let errMessage = 'Failed to upload the file, Please try again after sometime.';

      if (errFromServer && errFromServer !== 'Upload Failed') {
        errMessage = 'The file could not be processed because its content does not adhere to the specified template.';
      }

      dispatch(
        ActionCreators.setErrorMessageNotificationAndStatus({
          message: errMessage,
          // 'Apologies, We have encountered an issue on our side. Rest assured, we are monitoring your request, and you will receive an email notification once it is processed.',
          status: true,
        })
      );
      if (type === 'inventory') {
        dispatch(InventoryActionCreators.setUploadInventorySheetApiStatus('FAILED'));
      } else {
        dispatch(InventoryActionCreators.setUploadInventoryTransitSheetApiStatus('FAILED'));
      }
    }
  };
}
