import React from "react";
import { CloseCircleOutlined } from "@ant-design/icons";
import {
  OUT_OF_STOCK,
  OVER_STOCKED,
  IN_STOCK,
  LOW_STOCK,
} from "../../../../Utils/SlabPredictionFormUtils";
import "./DisplaySelectedFilter.scss";

const getProductStatusColor = (status) => {
  switch (status) {
    case OVER_STOCKED:
      return <div className="red-dot" />;
    case IN_STOCK:
      return <div className="green-dot" />;
    case LOW_STOCK:
      return <div className="yellow-dot" />;
    default:
      return <></>;
  }
};
function DisplaySelectedFilter(props) {
  const { productCount, productStatus, onClickCloseFilter } = props;
  return (
    <div className="display-selected-filter-wrapper">
      <div className="empty-div" />
      {getProductStatusColor(productStatus)}
      <div className="display-selected-filter-product-count">
        {productCount}
      </div>
      <div className="display-selected-filter-product-status">
        {productStatus}
      </div>
      <CloseCircleOutlined onClick={onClickCloseFilter} />
    </div>
  );
}

export default DisplaySelectedFilter;
