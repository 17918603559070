function checkPasswordErrors(password) {
  return {
    length: password.length >= 8,
    upperCase: password.search(/[A-Z]/g) >= 0,
    lowerCase: password.search(/[a-z]/g) >= 0,
    number: password.search(/[0-9]/) >= 0,
    specialCharacter: password.search(/[@!#$%&?_]/) >= 0,
  };
}

function checkPassword() {}

export { checkPasswordErrors, checkPassword };
