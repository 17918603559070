import React from "react";
import { Avatar, Tooltip } from "antd";
import { UserOutlined, LogoutOutlined } from "@ant-design/icons";
import "./SettingsMenu.scss";
import LogOutButton from "../Auth/LogOutButton";

const SettingsMenu = ({ email }) => {
  return (
    <div className="user-settings-content">
      <div className="user-settings-info">
        <div className="user-settings-avatar">
          <Avatar
            size={50}
            style={{ color: "#ffffff" }}
            icon={<UserOutlined />}
          ></Avatar>
        </div>
        <div className="user-settings-primary-info">
          <div className="user-settings-name">
            <Tooltip placement="top" title={email}>
              {email}
            </Tooltip>
          </div>
          <div className="user-settings-email">{email}</div>
        </div>
      </div>

      <div className="user-settings-option logout-wrapper">
        <LogOutButton
          renderProp={(handleClick, logoutButtonText) => (
            <span
              className="logout"
              onClick={() => handleClick()}
              role="presentation"
            >
              <LogoutOutlined style={{ fontSize: "10px" }} />
              <span style={{ paddingLeft: "8px" }}>{logoutButtonText}</span>
            </span>
          )}
        />
      </div>
    </div>
  );
};
export default SettingsMenu;
