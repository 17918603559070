import { getSlabPrediction } from '../Repository/SlabPredictionRepository';
import * as BranchActionCreators from './ActionCreators/BranchActionCreator';
import { setErrorMessageNotificationAndStatus } from './ActionCreators/SalesSheetActionCreator';
import * as ActionCreators from './ActionCreators/SlabPredictionActionCreator';

export function getSlabPredictionData(data) {
  return async (dispatch) => {
    try {
      dispatch(ActionCreators.setSlabPredictionApiStatus('INPROGRESS'));
      const slabPredictionApiResponse = await getSlabPrediction({
        branch: data.branch,
      });
      const slabPredictionData = slabPredictionApiResponse?.data;
      dispatch(
        ActionCreators.setSlabPredictionData({
          data: slabPredictionData,
          startDate: data?.date,
        })
      );
      dispatch(
        BranchActionCreators.setSlabPredictionDataByBranch({
          data: slabPredictionData,
          startDate: data?.date,
        })
      );
      dispatch(ActionCreators.setSlabPredictionApiStatus('COMPLETED'));
    } catch (error) {
      dispatch(
        setErrorMessageNotificationAndStatus({
          message: 'Data fetch failed. Please refresh and try again.',
          status: true,
        })
      );
      ActionCreators.setSlabPredictionApiStatus('FAILED');
    }
  };
}

export function setSlabPredictionDataWithStartDate(startDate) {
  return (dispatch) => {
    dispatch(ActionCreators.setSlabPredictionDataWithStartDate(startDate));
  };
}
