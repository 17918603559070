export function emailValidator(email) {
  if (!email || email.length > 128) {
    return false;
  }
  const regex =
    /^[-!#$%&'*+/0-9=?A-Z^_a-z`{|}~](\.?[-!#$%&'*+/0-9=?A-Z^_a-z`{|}~])*@[a-zA-Z0-9](-*\.?[a-zA-Z0-9])*\.[a-zA-Z](-?[a-zA-Z0-9])+$/;
  const emailParts = email.split("@");
  if (emailParts?.length !== 2) {
    return false;
  }
  const [account] = emailParts;
  if (account?.length > 64) {
    return false;
  }
  return !!regex.test(email.trim());
}
