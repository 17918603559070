import { Button, Col, Input, Row } from 'antd';
import React, { Component } from 'react';
import { emailValidator } from '../../Utils/ContentUtils/Validators';
import './ResetPassword.scss';

class ResetPassword extends Component {
  constructor(props) {
    super(props);
    this.state = {
      inputError: null,
    };
    this.handleChange = this.handleChange.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
  }

  handleChange(e) {
    const { name, value } = e.target;
    const formattedValue = value ? value.toLowerCase() : value;
    if (name) this.setState({ [name]: formattedValue, inputError: null });
  }

  onSubmit() {
    const { emailId } = this.state;
    if (!emailId) this.setState({ inputError: 'Please input email-id' });
    else if (!emailValidator(emailId)) this.setState({ inputError: 'Please enter valid email-id' });
    else {
      const { onSubmit } = this.props;
      if (onSubmit) onSubmit(emailId);
    }
  }

  render() {
    const { onCancel, sendingVerificatoniEmail } = this.props;
    const { inputError } = this.state;

    return (
      <Row style={{ width: '100%' }}>
        <Col xs={1} sm={4} lg={7} md={6} xl={8} xxl={9} />
        <Col xs={22} sm={16} lg={10} md={12} xl={8} xxl={6}>
          <div className="reset-password-wrapper">
            <div className="reset-password-content">
              <div>
                <div className="reset-password-forgot-text">Forgot Password</div>
                <div className="reset-password-reset-text">Enter your email, we will send you reset link </div>
                <div className="reset-password-email-text"> Email</div>
                <div className="reset-password-email-input">
                  <Input
                    name="emailId"
                    type="email"
                    onChange={this.handleChange}
                    className="resetpassword-input"
                    placeholder="ex. yourname@email.com"
                  />
                </div>
                <div style={{ color: 'red', paddingTop: '10px', paddingLeft: '42px' }}>{inputError}</div>
              </div>
            </div>
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                gap: '10px',
                textAlign: 'center',
              }}
            >
              <Button
                loading={sendingVerificatoniEmail}
                onClick={this.onSubmit}
                id="reset-password-btn"
                style={{
                  width: '75%',
                  marginLeft: '42px',
                  height: '50px',
                  background: '#51575E',
                  borderRadius: '8px',
                  color: '#FFFFFF',
                }}
              >
                Reset Password
              </Button>
              <div
                type="link"
                onClick={onCancel}
                className="reset-password-cancel-btn"
                style={{
                  textDecorationLine: 'underline',
                  fontSize: '14px',
                  color: '#007BFF',
                  cursor: 'pointer',
                  paddingTop: '18px',
                  paddingBottom: '20px',
                }}
              >
                Cancel
              </div>
            </div>
          </div>{' '}
        </Col>{' '}
        <Col xs={1} sm={4} lg={7} md={6} xl={7} xxl={7} />{' '}
      </Row>
    );
  }
}

export default ResetPassword;
