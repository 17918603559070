/* eslint-disable no-useless-computed-key */
import { Alert, Button, Col, DatePicker, Form, message, Row, Select, Skeleton } from 'antd';
import dayjs from 'dayjs';
import React, { useState } from 'react';
import { CSVLink } from 'react-csv';
import { useDispatch, useSelector } from 'react-redux';
import {
  setErrorMessageNotificationAndStatus,
  setRefreshNotificationVisibility,
  setSalesFileProcessStatus,
} from '../../Actions/ActionCreators/SalesSheetActionCreator';
import Config from '../../Config/Config.json';
import { getApiStatus } from '../../Reducers/ApiStatusReducer';
import {
  getErrorMessageNotificationAndStatus,
  getLatestSalesSheetData,
  getRefreshNotificationVisibilityStatus,
  getSalesFileDetailsByLocation,
} from '../../Reducers/SalesSheetReducer';
import {
  enrichPredictionTableForDownload,
  getFilteredPredictionTableBySelectedProduct,
} from '../../Utils/SlabPredictionTableUtils';
import SlabPredictionOverview from '../SlabPredictionOverview/SlabPredictionOverview';
import SlabPredictionTable from '../SlabPredictionTable/SlabPredictionTable';
import UploadedInventorySheet from '../UploadedInventorySheet/UploadedInventorySheet';
import UploadedSaleSheet from '../UploadedSaleSheet/UploadedSaleSheet';
import './SlabPredictionForm.scss';

const { predictions_durations } = Config;
const { RangePicker } = DatePicker;
const { Option } = Select;

function getDurationOptions() {
  return predictions_durations.map((duration) => (
    <Option key={duration} value={duration}>
      {`Next ${duration} days`}
    </Option>
  ));
}

function SlabPredictionForm(props) {
  const {
    productList,
    form,
    generateTableApiStatus,
    predictionTables,
    slabPredictionStartDateList,
    salesSheetApiStatus,
    slabPredictionCurrStartDate,
    changePredictionTableFromStore,
    fetchPredictionTable,
    currentBranch,
  } = props;

  const dispatch = useDispatch();

  const { salesinfo: latestSalesData } = useSelector((state) => getLatestSalesSheetData(state));
  const uploadSalesSheetApiStatus = useSelector((state) => getApiStatus(state, 'UploadSalesSheetApiStatus'));
  const uploadedFileProcessStatus = useSelector((state) => getApiStatus(state, 'SalesFileProcessStatus'));
  const salesFileDetailsByLocation = useSelector((state) => getSalesFileDetailsByLocation(state)) ?? {};
  const refreshNotificationVisibility = useSelector((state) => getRefreshNotificationVisibilityStatus(state));

  const { message: currentErrorMessage, status: currentErrorMessageStatus } = useSelector((state) =>
    getErrorMessageNotificationAndStatus(state)
  );

  const [displayPredictionTable, setDisplayPredictionTable] = useState(predictionTables?.enriched90DaysTable);
  const [dataToDownload, setDataToDownload] = useState(predictionTables?.enriched90DaysTable);
  const [predictionDuration, setPredictionDuration] = useState(predictions_durations[0]);
  const [selectallProductData, setSelectedAllProductData] = useState({});

  React.useEffect(() => {
    if (generateTableApiStatus === 'COMPLETED') {
      const selectedProductListFromForm = form.getFieldValue('selectedProductList') ?? [];
      if (selectedProductListFromForm.length === 0) {
        if (predictionDuration === 90) {
          setDisplayPredictionTable(predictionTables?.enriched90DaysTable);
          setSelectedAllProductData(predictionTables?.allDataPrediction?.all90DaysPredictionData);
        } else if (predictionDuration === 180) {
          setDisplayPredictionTable(predictionTables.enriched180DaysTable);
          setSelectedAllProductData(predictionTables?.allDataPrediction?.all180DaysPredictionData);
        } else if (predictionDuration === 270) {
          setDisplayPredictionTable(predictionTables.enriched270DaysTable);
          setSelectedAllProductData(predictionTables?.allDataPrediction?.all270DaysPredictionData);
        } else if (predictionDuration === 365) {
          setDisplayPredictionTable(predictionTables.enriched360DaysTable);
          setSelectedAllProductData(predictionTables?.allDataPrediction?.all365DaysPredictionData);
        }
      } else if (selectedProductListFromForm && selectedProductListFromForm.length > 0) {
        if (predictionDuration === 90) {
          const filteredPredictionTableBySelectedProduct = getFilteredPredictionTableBySelectedProduct(
            selectedProductListFromForm,
            predictionTables?.enriched90DaysTable
          );
          setDisplayPredictionTable(filteredPredictionTableBySelectedProduct);
          setSelectedAllProductData(predictionTables?.allDataPrediction?.all90DaysPredictionData);
        } else if (predictionDuration === 180) {
          const filteredPredictionTableBySelectedProduct = getFilteredPredictionTableBySelectedProduct(
            selectedProductListFromForm,
            predictionTables?.enriched180DaysTable
          );
          setDisplayPredictionTable(filteredPredictionTableBySelectedProduct);
          setSelectedAllProductData(predictionTables?.allDataPrediction?.all180DaysPredictionData);
        } else if (predictionDuration === 270) {
          const filteredPredictionTableBySelectedProduct = getFilteredPredictionTableBySelectedProduct(
            selectedProductListFromForm,
            predictionTables?.enriched270DaysTable
          );
          setDisplayPredictionTable(filteredPredictionTableBySelectedProduct);
          setSelectedAllProductData(predictionTables?.allDataPrediction?.all270DaysPredictionData);
        } else if (predictionDuration === 365) {
          const filteredPredictionTableBySelectedProduct = getFilteredPredictionTableBySelectedProduct(
            selectedProductListFromForm,
            predictionTables?.enriched360DaysTable
          );
          setDisplayPredictionTable(filteredPredictionTableBySelectedProduct);
          setSelectedAllProductData(predictionTables?.allDataPrediction?.all365DaysPredictionData);
        }
      }
    }
  }, [generateTableApiStatus]);

  React.useEffect(() => {
    onClearSelectedProductList();
  }, [currentBranch]);

  React.useEffect(() => {
    onClickGenerateResult();
  }, [predictionDuration]);

  const onSelectProduct = (value) => {
    let selectedProductList = form.getFieldValue('selectedProductList') ?? [];

    selectedProductList = [...selectedProductList, value];
    form.setFieldsValue({ ['selectedProductList']: selectedProductList });
  };

  const onClearSelectedProductList = () => {
    form.setFieldsValue({ ['selectedProductList']: [] });
    form.setFieldsValue({ ['productList']: [] });
  };

  const onDeselectProduct = (value) => {
    const selectedProductList = form.getFieldValue('selectedProductList') ?? [];
    const filteredProductList = selectedProductList.filter((productName) => productName !== value);
    form.setFieldsValue({ ['selectedProductList']: filteredProductList });
  };

  const onClickGenerateResult = () => {
    const predictionStartDate = dayjs(latestSalesData?.actual_date).format('YYYY-MM-DD') ?? '';

    if (
      slabPredictionStartDateList?.includes(predictionStartDate) &&
      slabPredictionCurrStartDate === predictionStartDate
    ) {
      const selectedProductListFromForm = form.getFieldValue('selectedProductList') ?? [];
      if (selectedProductListFromForm.length === 0) {
        if (predictionDuration === 90) {
          setDisplayPredictionTable(predictionTables.enriched90DaysTable);
          setSelectedAllProductData(predictionTables?.allDataPrediction?.all90DaysPredictionData);
        } else if (predictionDuration === 180) {
          setDisplayPredictionTable(predictionTables.enriched180DaysTable);
          setSelectedAllProductData(predictionTables?.allDataPrediction?.all180DaysPredictionData);
        } else if (predictionDuration === 270) {
          setDisplayPredictionTable(predictionTables.enriched270DaysTable);
          setSelectedAllProductData(predictionTables?.allDataPrediction?.all270DaysPredictionData);
        } else if (predictionDuration === 365) {
          setDisplayPredictionTable(predictionTables.enriched360DaysTable);
          setSelectedAllProductData(predictionTables?.allDataPrediction?.all365DaysPredictionData);
        }
      } else if (selectedProductListFromForm && selectedProductListFromForm.length > 0) {
        if (predictionDuration === 90) {
          const filteredPredictionTableBySelectedProduct = getFilteredPredictionTableBySelectedProduct(
            selectedProductListFromForm,
            predictionTables?.enriched90DaysTable
          );
          setDisplayPredictionTable(filteredPredictionTableBySelectedProduct);
          setSelectedAllProductData(predictionTables?.allDataPrediction?.all90DaysPredictionData);
        } else if (predictionDuration === 180) {
          const filteredPredictionTableBySelectedProduct = getFilteredPredictionTableBySelectedProduct(
            selectedProductListFromForm,
            predictionTables?.enriched180DaysTable
          );
          setDisplayPredictionTable(filteredPredictionTableBySelectedProduct);
          setSelectedAllProductData(predictionTables?.allDataPrediction?.all180DaysPredictionData);
        } else if (predictionDuration === 270) {
          const filteredPredictionTableBySelectedProduct = getFilteredPredictionTableBySelectedProduct(
            selectedProductListFromForm,
            predictionTables?.enriched270DaysTable
          );
          setDisplayPredictionTable(filteredPredictionTableBySelectedProduct);
          setSelectedAllProductData(predictionTables?.allDataPrediction?.all270DaysPredictionData);
        } else if (predictionDuration === 365) {
          const filteredPredictionTableBySelectedProduct = getFilteredPredictionTableBySelectedProduct(
            selectedProductListFromForm,
            predictionTables?.enriched360DaysTable
          );
          setSelectedAllProductData(predictionTables?.allDataPrediction?.all365DaysPredictionData);
          setDisplayPredictionTable(filteredPredictionTableBySelectedProduct);
        }
      }
    } else if (
      slabPredictionStartDateList?.includes(predictionStartDate) &&
      slabPredictionCurrStartDate !== predictionStartDate
    ) {
      changePredictionTableFromStore(predictionStartDate);
    } else if (!slabPredictionStartDateList?.includes(predictionStartDate)) {
      fetchPredictionTable(predictionStartDate);
    }
  };

  const lastUploadedSalesDate = dayjs(form?.getFieldValue('predictionStartDate')).format('DD-MM-YYYY') ?? '';

  const fileUploadingDisplay = (
    <Button className="uploadingFileInfo" loading>
      Uploading...
    </Button>
  );

  const fileProcessingDispay = (
    <Button className="processingFileInfo" loading>
      Processing...
    </Button>
  );

  const refreshData = () => {
    onClearSelectedProductList();
    fetchPredictionTable();
    onClickGenerateResult();
    dispatch(setRefreshNotificationVisibility(false));
  };

  const showErrorMessage =
    currentErrorMessageStatus &&
    message.error({
      content: currentErrorMessage,
      duration: 5,
      onClose: () => {
        dispatch(
          setErrorMessageNotificationAndStatus({
            message: currentErrorMessage,
            status: false,
          })
        );
      },
    });

  const processingCompletedAlert = () => {
    if (
      refreshNotificationVisibility &&
      uploadSalesSheetApiStatus !== 'INPROGRESS' &&
      uploadedFileProcessStatus !== 'PENDING' &&
      uploadedFileProcessStatus !== 'FAILED'
    ) {
      return (
        <Alert
          message={
            <>
              <div className="processingCompletedAlert">
                Processing Completed{' '}
                <Button onClick={refreshData} type="link">
                  Refresh Data
                </Button>
              </div>
              <div className="alertInfo">Upon refreshing, all the filters will reset</div>
            </>
          }
          type="info"
          showIcon
          style={{
            position: 'fixed',
            top: 10,
            zIndex: 999,
            left: '35%',
            width: '400px',
            display: 'flex',
          }}
        />
      );
    }
    if (uploadedFileProcessStatus === 'FAILED') {
      return (
        <Alert
          message={
            <>
              <div className="processingCompletedAlert">Processing Failed</div>
              <div className="alertInfo">
                Apologies, We&apos;ve encountered an issue on our side. Rest assured, we&apos;re monitoring your
                request, and you&apos;ll receive an email notification once it&apos;s been processed.
              </div>
            </>
          }
          onClose={() => dispatch(setSalesFileProcessStatus(undefined))}
          closable
          type="warning"
          showIcon
          style={{
            position: 'fixed',
            top: 10,
            zIndex: 999,
            left: '35%',
            width: '400px',
            display: 'flex',
          }}
        />
      );
    }
    return null;
  };

  const displayDateRanges = () => {
    const { actual_date: actualDate } = latestSalesData || {};
    const startDate = dayjs(actualDate).format('DD/MM/YYYY');
    const endDate = dayjs(actualDate).add(predictionDuration, 'd').format('DD/MM/YYYY');
    return <span className="date-range-text">{`${startDate} - ${endDate}`}</span>;
  };

  const displayRangePickerAndFilter = (
    <div className="slab-prediction-form-input">
      <Row style={{ width: '100%' }} gutter={[8, 0]}>
        <Col xs={24} sm={24} md={24} lg={10} xl={9} xxl={10}>
          <div className="slab-prediction-form-input-content">
            <Form.Item
              name="dateRange"
              rules={[
                {
                  required: false,
                  message: 'Please enter start date',
                },
              ]}
              // className="slab-prediction-form-date-range"
              // className="slab-prediction-form-product-list"
              initialValue={predictionDuration}
            >
              <Select size="large" onSelect={setPredictionDuration}>
                {getDurationOptions()}
              </Select>
            </Form.Item>
            {displayDateRanges()}
          </div>
        </Col>

        <Col xs={24} sm={24} md={24} lg={10} xl={11} xxl={10}>
          <Form.Item name="productList" className="slab-prediction-form-product-list">
            <Select
              allowClear={true}
              onClear={onClearSelectedProductList}
              mode="multiple"
              size="middle"
              placeholder="Enter products"
              style={{
                fontFamily: 'AvertaStd-Regular',
              }}
              options={productList}
              tokenSeparators={[',']}
              onSelect={onSelectProduct}
              onDeselect={onDeselectProduct}
            />
          </Form.Item>
        </Col>
        <Col xs={24} sm={24} md={24} lg={4} xl={4} xxl={4}>
          <Button type="button" className="slab-prediction-form-generate-result-btn" onClick={onClickGenerateResult}>
            Generate Results
          </Button>
        </Col>
      </Row>
    </div>
  );

  const fileName = latestSalesData?.file_name || salesFileDetailsByLocation[`${currentBranch}`]?.file_name;

  return (
    <Skeleton loading={generateTableApiStatus === 'INPROGRESS' || salesSheetApiStatus === 'INPROGRESS'}>
      <div className="slab-prediction-form-wrapper">
        <Form form={form}>
          <div className="uploaded-sheets-container">
            <UploadedSaleSheet
              fileUploadingDispay={fileUploadingDisplay}
              fileProcessingDispay={fileProcessingDispay}
              currentBranch={currentBranch}
            />
            <UploadedInventorySheet
              type="inventory"
              fileUploadingDisplay={fileUploadingDisplay}
              currentBranch={currentBranch}
            />
            <UploadedInventorySheet
              type="transit"
              fileUploadingDisplay={fileUploadingDisplay}
              currentBranch={currentBranch}
            />
          </div>
          {processingCompletedAlert()}
          {showErrorMessage}
          {fileName?.length ? displayRangePickerAndFilter : <br />}
        </Form>
        {fileName?.length ? (
          <>
            <SlabPredictionOverview allDataPrediction={selectallProductData} currentBranch={currentBranch} />
            <SlabPredictionTable
              predictionTables={predictionTables}
              generateTableApiStatus={generateTableApiStatus}
              displayPredictionTable={displayPredictionTable}
              setDataToDownload={setDataToDownload}
            />
            <Row
              gutter={[8, 0]}
              style={{
                width: '100%',
                paddingTop: '50px',
                paddingBottom: '20px',
              }}
            >
              <Col xs={11} sm={15} lg={17} md={15} xl={19} xxl={19} />
              <Col xs={13} sm={9} lg={7} md={9} xl={5} xxl={5}>
                <CSVLink
                  data={enrichPredictionTableForDownload(dataToDownload, selectallProductData)}
                  filename={`Generated_Results_${currentBranch}_${lastUploadedSalesDate}_${predictionDuration}_days.csv`}
                  className="csv-link"
                >
                  <Button className="slab-prediction-form-generate-result-btn" type="primary">
                    Export item to order
                  </Button>{' '}
                </CSVLink>
              </Col>
            </Row>
          </>
        ) : null}
      </div>
    </Skeleton>
  );
}

export default SlabPredictionForm;
