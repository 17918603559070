import React, { Component } from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import { configureCognito } from "./Cognito/Configure";
import App from "./App";

configureCognito();

export default class SsoRoutes extends Component {
  render() {
    return (
      <Router>
        <Switch>
          <Route component={App} />
        </Switch>
      </Router>
    );
  }
}
