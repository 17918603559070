import { Progress } from 'antd';
import React from 'react';
import { IN_STOCK, LOW_STOCK, OVER_STOCKED, productStatusColorMapping } from '../../Utils/SlabPredictionFormUtils';
import './SlabPredictionOverview.scss';

function SlabPredictionOverview(props) {
  const { allDataPrediction, currentBranch } = props;

  return (
    <div className="slab-prediction-overview-wrapper">
      <div className="slab-prediction-overview-branch">
        <div style={{ fontFamily: 'AvertaStd-Semibold', fontSize: '15px' }}>{currentBranch}</div>
        <div style={{ color: '#83878C', fontFamily: '13px' }}>
          {allDataPrediction?.status === OVER_STOCKED || allDataPrediction?.status === LOW_STOCK
            ? allDataPrediction?.status
            : null}
        </div>
      </div>
      <div className="slab-prediction-overview-stock">
        <div>In Stock / Predicted Sale</div>
        <div
          style={{
            color: '#1F2730',
            fontFamily: 'AvertaStd-Semibold',
            fontSize: '14px',
          }}
        >
          <div>
            {allDataPrediction?.prediction?.length > 0 ? allDataPrediction?.prediction[0] : 0} /{' '}
            {allDataPrediction?.prediction?.length > 0 ? allDataPrediction?.prediction[1] : 0}
          </div>
          <Progress
            percent={
              (allDataPrediction?.prediction?.length > 0
                ? allDataPrediction?.prediction[2]
                : 0 / allDataPrediction?.prediction?.length > 0
                ? allDataPrediction?.prediction[3]
                : 1) * 100
            }
            showInfo={false}
            style={{ width: '62px' }}
            className="slab-prediction-overview-progress-bar"
            strokeColor={
              productStatusColorMapping[
                allDataPrediction?.prediction?.length > 0 ? allDataPrediction?.prediction[4] : IN_STOCK
              ]
            }
          />
        </div>
      </div>
      <div className="slab-prediction-overview-confidence">
        <div style={{ color: '#83878C' }}>Confidence</div>
        <div style={{ fontFamily: 'AvertaStd-Semibold' }}>{allDataPrediction?.confidence} %</div>
      </div>
    </div>
  );
}

export default SlabPredictionOverview;
