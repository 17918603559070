/* eslint-disable no-alert */
import { SignIn } from "aws-amplify-react";
import {
  BrowserRouter as Router,
  Redirect,
  Route,
  Switch,
} from "react-router-dom";
import LoginContainer from "./Containers/LoginContainer/LoginContainer";
import ResetPassword from "./Containers/ResetPassword/ResetPassword";

class UnauthorizedRoutes extends SignIn {
  constructor(props) {
    super(props);
    this._validAuthStates = ["signIn", "signedOut"];
    this.changeState = this.changeState.bind(this);
  }

  showComponent() {
    return (
      <div>
        <Router>
          <Switch>
            <Route
              path="/resetForgotPassword"
              exact
              render={() => <ResetPassword />}
            />
            <Route
              path="/"
              exact
              render={() => <LoginContainer changeState={this.changeState} />}
            />
            <Route path="/*" render={() => <Redirect to="/" />} />
          </Switch>
        </Router>
      </div>
    );
  }
}

export default UnauthorizedRoutes;
