import { Form } from 'antd';
import dayjs from 'dayjs';
import _ from 'lodash';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getLastestFileUploadedData } from '../../Actions/SalesSheetActions';
import {
  getSlabPredictionData as _getSlabPredictionData,
  setSlabPredictionDataWithStartDate,
} from '../../Actions/SlabPredictionActions';
import SlabPredictionForm from '../../Components/SlabPredictionForm/SlabPredictionForm';
import { getApiStatus } from '../../Reducers/ApiStatusReducer';
import {
  getCurrentBranch,
  getCurrentBranchSlabPredictionData,
  getLatestSalesSheetDataByBranch,
  getSlabPredictionCurrentStartDateByBranch,
  getSlabPredictionStartDatesByBranch,
} from '../../Reducers/BranchReducer';
import { getFilteredProductList, _enrichPredictionTables } from '../../Utils/SlabPredictionFormUtils';
import './SlabPredictionContainer.scss';

function SlabPredictionContainer(props) {
  const dispatch = useDispatch();

  const salesSheetApiStatus = useSelector((state) => getApiStatus(state, 'SalesSheetApiStatus'));
  const currentBranch = useSelector((state) => getCurrentBranch(state));
  const generatePredictionTableApiStatus = useSelector((state) => getApiStatus(state, 'SlabPredictionApiStatus'));
  const slabPredictionStartDateList = useSelector((state) => getSlabPredictionStartDatesByBranch(state));
  const slabPredictionCurrStartDate = useSelector((state) => getSlabPredictionCurrentStartDateByBranch(state));
  const rawPredictionTable = useSelector((state) => getCurrentBranchSlabPredictionData(state));
  const lastestFileDetails = useSelector((state) => getLatestSalesSheetDataByBranch(state));

  const predictionTables = _enrichPredictionTables(rawPredictionTable);
  const productList = getFilteredProductList(rawPredictionTable);
  const [form] = Form.useForm();

  useEffect(() => {
    dispatch(getLastestFileUploadedData({ branch: currentBranch }));
  }, [currentBranch]);

  const fetchPredictionTable = (startDate) => {
    const payload = {
      date: startDate || dayjs(lastestFileDetails?.salesinfo?.actual_date).format('YYYY-MM-DD'),
      branch: currentBranch,
    };
    dispatch(_getSlabPredictionData(payload));
  };

  useEffect(() => {
    if (!_.isEmpty(lastestFileDetails)) {
      fetchPredictionTable();
    }
  }, [lastestFileDetails]);

  const changePredictionTableFromStore = (startDate) => {
    dispatch(setSlabPredictionDataWithStartDate(startDate));
  };

  return (
    <div className="slab-prediction-container-wrapper">
      <SlabPredictionForm
        form={form}
        predictionTables={predictionTables}
        productList={productList}
        generateTableApiStatus={generatePredictionTableApiStatus}
        slabPredictionStartDateList={slabPredictionStartDateList}
        slabPredictionCurrStartDate={slabPredictionCurrStartDate}
        salesSheetApiStatus={salesSheetApiStatus}
        currentBranch={currentBranch}
        changePredictionTableFromStore={changePredictionTableFromStore}
        fetchPredictionTable={fetchPredictionTable}
      />
    </div>
  );
}

export default SlabPredictionContainer;
