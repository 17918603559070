import { Auth, Analytics } from "aws-amplify";
import config from "../Config/Config.json";

export function configureCognito() {
  Analytics.configure({ disabled: true });

  Auth.configure({
    region: config.cognito.region,
    userPoolId: config.cognito.poolId,
    userPoolWebClientId: config.cognito.clientId,
  });
}
