import React from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { Auth } from "aws-amplify";
import { SignOut } from "aws-amplify-react";
import { signOut } from "../../Actions/AuthActions";

const mapDispatchToProps = {
  signOut: signOut,
};
class LogOutButton extends SignOut {
  constructor(props) {
    super(props);
    this.handleClick = this.handleClick.bind(this);
  }

  handleClick() {
    const { signOut } = this.props;
    super.signOut();
    signOut();
    window.location.pathname = "/";
  }

  render() {
    const { renderProp } = this.props;

    return <div>{renderProp(this.handleClick, <>Logout</>)}</div>;
  }
}

export default connect(null, mapDispatchToProps)(LogOutButton);
