import _ from 'lodash';
import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { getAuthChangePasswordRequiredStatus } from '../../Reducers/AuthenticationReducer';

function OnBoardingUserContainer(props) {
  const { history } = props;
  const changePasswordRequired = useSelector((state) => getAuthChangePasswordRequiredStatus(state));
  useEffect(() => {
    if (_.isEmpty(changePasswordRequired)) {
      history.push('/slabPrediction');
    } else {
      history.push('/resetPassword');
    }
  }, []);
  return <div></div>;
}

export default OnBoardingUserContainer;
