import { connectRouter, routerMiddleware } from 'connected-react-router';
import { applyMiddleware, combineReducers, compose, createStore } from 'redux';
import logger from 'redux-logger';
import thunk from 'redux-thunk';
import history from './history';
import { ApiStatusReducer } from './Reducers/ApiStatusReducer';
import { AuthenticationReducer } from './Reducers/AuthenticationReducer';
import { BranchReducer } from './Reducers/BranchReducer';
import { SalesSheetReducer } from './Reducers/SalesSheetReducer';
import { SlabPredictionReducer } from './Reducers/SlabPredictionReducer';

const combinedReducer = combineReducers({
  SlabPredictionReducer,
  SalesSheetReducer,
  BranchReducer,
  ApiStatusReducer,
  AuthenticationReducer,
  router: connectRouter(history),
});

export const rootReducer = (state, action) => {
  if (action.type === 'USER_LOGOUT') {
    localStorage.clear();
    return combinedReducer(undefined, action);
  }
  return combinedReducer(state, action);
};

let composeEnhancers = compose;
let middlewares = [thunk, routerMiddleware(history)];

if (process.env.NODE_ENV === 'development') {
  middlewares = [...middlewares, logger];
  composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
}

const store = createStore(rootReducer, {}, composeEnhancers(applyMiddleware(...middlewares)));

export default store;
