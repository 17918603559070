import {
  setCurrentBranch,
  setSlabPredictionDataByBranch,
} from "./ActionCreators/BranchActionCreator";

export const _setCurrentBranch = (data) => {
  return (dispatch) => {
    dispatch(setCurrentBranch(data));
  };
};
export const _setSlabPredictionDataByBranch = (data) => {
  return (dispatch) => {
    dispatch(setSlabPredictionDataByBranch(data));
  };
};
export const _setSalesSheetDataByBranch = (data) => {
  return (dispatch) => {
    dispatch(setSlabPredictionDataByBranch);
  };
};
