import cleanSet from 'clean-set';

const initialState = {
  SlabPredictionData: {},
  SlabPredictionDataByDate: {},
  SlabPredictionCurrentStartDate: '',
  SlabPredictionStartDates: [],
};

const emptyObject = {};

const SlabPredictionReducer = (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case 'SET_SLAB_PREDICTION_DATA':
      const { startDate, data } = payload;
      let newState;
      const newPredictionData = {};
      newPredictionData[startDate] = data;

      newState = cleanSet(state, 'SlabPredictionData', data);
      newState = cleanSet(newState, 'SlabPredictionDataByDate', {
        ...state.SlabPredictionDataByDate,
        ...newPredictionData,
      });
      newState = cleanSet(newState, 'SlabPredictionCurrentStartDate', startDate);
      if (!state.SlabPredictionStartDates?.includes(startDate)) {
        newState = cleanSet(newState, 'SlabPredictionStartDates', [...state.SlabPredictionStartDates, startDate]);
      }
      return newState;
    case 'SET_SLAB_PREDICTION_DATA_WITH_START_DATE':
      const newSlabPredictionData = state?.SlabPredictionDataByDate[payload];
      return {
        ...state,
        SlabPredictionData: newSlabPredictionData,
        SlabPredictionCurrentStartDate: payload,
      };
    default:
      return state;
  }
};

export function getSlabPredictionData(state) {
  return state?.SlabPredictionReducer?.SlabPredictionData;
}

export function getSlabPredictionDataByDate(state, startDate) {
  return state?.SlabPredictionReducer?.SlabPredictionDataByDate[startDate] ?? emptyObject;
}

export function getSlabPredictionCurrentStartDate(state) {
  return state?.SlabPredictionReducer?.SlabPredictionCurrentStartDate;
}

export function getSlabPredictionStartDates(state) {
  return state?.SlabPredictionReducer?.SlabPredictionStartDates;
}

export { SlabPredictionReducer };
