import { setApiStatus } from '../ApiStatusActions';

export function setSlabPredictionData(data) {
  return { type: 'SET_SLAB_PREDICTION_DATA', payload: data };
}

export function setSlabPredictionApiStatus(status) {
  const apiName = 'SlabPredictionApiStatus';
  return setApiStatus({ apiName, status });
}

export function setSlabPredictionDataWithStartDate(startDate) {
  return { type: 'SET_SLAB_PREDICTION_DATA_WITH_START_DATE', payload: startDate };
}
