import { Auth } from 'aws-amplify';
import secureLocalStorage from 'react-secure-storage';
import {
  setAuthChangePasswordRequired,
  setAuthLoginError,
  setSignInStatus,
} from './ActionCreators/AuthenticationActionCreator';

export function logIn(username, password) {
  return (dispatch) => {
    Auth.signIn(username, password)
      .then((res) => {
        secureLocalStorage.setItem('userEmail', username);
        dispatch({
          type: 'SET_AUTHENTICATED_USER',
          payload: { attributes: res.attributes, username: res.username },
        });
        if (res.challengeName === 'NEW_PASSWORD_REQUIRED') {
          dispatch(setAuthChangePasswordRequired(res));
          dispatch(setSignInStatus('COMPLETED'));
        } else {
          dispatch(setSignInStatus('COMPLETED'));
        }
      })
      .catch((err) => {
        let error = {
          cognitoError: err,
        };
        console.error(error);
        if (err.code === 'UserNotFoundException') {
          error.userExists = false;
          error.message = 'User not found.';
        } else if (err.code === 'NotAuthorizedException') {
          error.userExists = true;
          error.message = 'Incorrect username or password.';
        } else if (err.code === 'UserNotConfirmedException') {
          error.verificationRequired = true;
        } else {
          error = err;
        }
        dispatch(setAuthLoginError(error));
      });
  };
}

export const setAuthenticatedUser = () => {
  return async (dispatch) => {
    try {
      const res = await Auth.currentUserInfo();
      dispatch({ type: 'SET_AUTHENTICATED_USER', payload: res });
    } catch (error) {
      console.log(error);
    }
  };
};
