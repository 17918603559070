import { Button, Form, Input, message } from 'antd';
import { Auth } from 'aws-amplify';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import * as AuthenticationReducer from '../../Reducers/AuthenticationReducer';
import { checkPasswordErrors } from '../../Utils/PasswordUtils';
import './ResetForgotPassword.scss';

const mapStateToProps = (state) => ({
  authChangePasswordRequired: AuthenticationReducer.getAuthChangePasswordRequiredStatus(state),
});
export class ResetForgotPassword extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isPasswordChanged: false,
      validPassword: {
        length: null,
        upperCase: null,
        lowerCase: null,
        number: null,
        specialCharacter: null,
      },
      showAllPasswordErrors: null,
      passwordsConsistent: null,
    };
    this.onChangePassword = this.onChangePassword.bind(this);
    this.resetPassword = this.resetPassword.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.validatePassword = this.validatePassword.bind(this);
    this.validateBothPasswords = this.validateBothPasswords.bind(this);
    this.handlePasswordBlur = this.handlePasswordBlur.bind(this);
  }

  onChangePassword() {
    const { newPassword } = this.state;

    if (newPassword) this.resetPassword(newPassword);
  }

  resetPassword(password) {
    const { history, authChangePasswordRequired } = this.props;
    if (!password) return;
    Auth.completeNewPassword(authChangePasswordRequired, password)
      .then(() => {
        this.setState({ isPasswordChanged: true, updatingPassword: false });
        history.push('/slabPrediction');
      })
      .catch((err) => {
        this.setState({ updatingPassword: false });
        message.error('Could not process your request at this moment');
        history.push('/');
      });
  }

  handleChange(e) {
    const { name, value } = e.target;
    if (name) this.setState({ [name]: value });

    if (name === 'newPassword') {
      this.validatePassword(value);

      const { confirmPassword } = this.state;
      if (confirmPassword) this.validateBothPasswords(value, confirmPassword);
    }

    if (name === 'confirmPassword') {
      const { newPassword } = this.state;
      this.validateBothPasswords(newPassword, value);
    }
  }

  validatePassword(password) {
    const passwordErrors = checkPasswordErrors(password);
    this.setState({ validPassword: passwordErrors });
  }

  validateBothPasswords(newPassword, confirmPassword) {
    if (newPassword && confirmPassword) {
      if (newPassword === confirmPassword) {
        this.setState({ passwordsConsistent: true });
        // return true;
      } else {
        this.setState({ passwordsConsistent: false });
      }
    }
    return false;
  }

  handlePasswordBlur() {
    this.setState({ showAllPasswordErrors: true });
  }

  loadPasswordScreen() {
    const { newPassword, passwordsConsistent, updatingPassword } = this.state;
    const canContinue = newPassword && passwordsConsistent;
    return (
      <div className="resetforgot-password-wrapper">
        <div className="inputs-alignment-panel">
          <div className="forgot-password-email-label"> Enter New Password</div>
          {/* <div className="forgot-password-password-label">Password</div> */}
          <div className="forgot-password-input-field">
            <Form.Item formitemlayout="vertical" colon={false} >
              <Input.Password
                name="newPassword"
                onChange={this.handleChange}
                onBlur={this.handlePasswordBlur}
                placeholder="New Password"
              />
            </Form.Item>
            <Form.Item formitemlayout="vertical" colon={false} >
              <Input.Password
                onBlur={(e) => this.validateBothPasswords(newPassword, e.target.value)}
                name="confirmPassword"
                onChange={this.handleChange}
                placeholder="Confirm password"
              />
            </Form.Item>
          </div>
          <div className="forgot-password-password-error">
            {passwordsConsistent !== null && !passwordsConsistent ? 'Password does not match.' : null}
          </div>
        </div>

        <div style={{ textAlign: "center" }}>
          <Button
            loading={updatingPassword}
            disabled={!canContinue}
            onClick={() => {
              this.setState({ updatingPassword: true });
              this.onChangePassword();
            }}
            className="forgot-password-reset-btn"
          >
            {updatingPassword ? 'Updating' : 'Reset Password'}
          </Button>
        </div>
      </div>
    );
  }

  render() {
    return <div style={{ display: 'flex', justifyContent: 'center' }}>{this.loadPasswordScreen()}</div>;
  }
}

export default withRouter(connect(mapStateToProps, null)(ResetForgotPassword));
