import Config from '../Config/Config.json';
import { getUserEmail } from '../Reducers/AuthenticationReducer';
import { getCurrentBranch } from '../Reducers/BranchReducer';
import { getSalesFileDetailsByLocation } from '../Reducers/SalesSheetReducer';
import * as ApplicationRepository from '../Repository/ApplicationRepository';
import * as ActionCreators from './ActionCreators/SalesSheetActionCreator';

const { appEmails } = Config;

export const sendEmail = (data, updateStore) => {
  return async (dispatch, getStore) => {
    const { file_type: fileType } = data;
    try {
      dispatch({ type: 'SENDING_EMAIL', payload: fileType });
      const store = getStore();
      const userEmail = getUserEmail(store);

      const currentBranch = getCurrentBranch(store);

      let sheetData;

      if (fileType === 'salessheet') {
        sheetData = getSalesFileDetailsByLocation(store)[currentBranch];
      } else if (fileType === 'inventory') {
        // sheetData = getInventoryFileDetailsByLocation(store)[currentBranch];
        sheetData = data;
      } else {
        // sheetData = getInventoryTransitFileDetailsByLocation(store)[currentBranch];
        sheetData = data;
      }

      delete sheetData.xlsx_data;
      delete sheetData.message;

      const formattedDataForUser = {
        Branch: sheetData.branch,
        File_Type: sheetData.file_type,
        Uploaded_Date: sheetData.last_uploaded_date,
        Filename: sheetData.file_name,
      };

      const toHelpData = {
        receiver_email: appEmails.help,
        // receiver_email: 'help@slabpredictions.com',
        subject: `Client Ticket - ${fileType === 'salessheet' ? 'Sales' : 'Inventory'} File Processing Error`,
        message: `Error occured while client tried to upload sales file. \n ${JSON.stringify(sheetData)}`,
      };

      const toUserData = {
        receiver_email: userEmail,
        // receiver_email: 'help@slabpredictions.com',
        subject: `Slab Predictions App - Regarding Issue with Your ${
          fileType === 'salessheet' ? 'Sales' : 'Inventory'
        }  File`,
        message: `Hello,\n\nGreetings from Slab Predictions Team!\n\nWe are writing with respect to an issue that we recently observed while processing your file. We want to assure you that resolving this matter is of the utmost importance to us, and our dedicated team is actively investigating the situation.\n\nFor your reference, here are the details related to your file:\nBranch: ${formattedDataForUser.Branch}\nFile Type: ${formattedDataForUser.File_Type}\nUploaded Date: ${formattedDataForUser.Uploaded_Date}\nFile Name: ${formattedDataForUser.Filename}\n\nWe understand that this situation may be inconvenient, and we apologize for any disruption it may have caused. Rest assured, we are committed to finding a swift resolution and will keep you informed of our progress through email updates.\n\nYour feedback is invaluable to us, as it helps us improve our services. Please do not hesitate to share your experience with us by writing to help@slabpredictions.com.\n\nPlease note that this email was sent from an address that does not accept incoming emails. To contact us regarding this case or share your experience, kindly use your registered email address and reach out to us at help@slabpredictions.com.Thank you for your understanding and patience as we work towards a resolution. We appreciate your trust in Slab Predictions, and we are dedicated to delivering the high-quality service you deserve.\n\nWishing you a wonderful day.\n\nBest regards,\nSlab Predictions Team`,
      };

      await ApplicationRepository.sendEmail(toHelpData);
      await ApplicationRepository.sendEmail(toUserData);
      dispatch({ type: 'SENDING_EMAIL_FULFILLED', payload: fileType });
      if (updateStore) {
        dispatch(ActionCreators.setSalesFileProcessStatus('FAILED'));
      }

      // <Alert
      //   message={
      //     <>
      //       <div className="processingCompletedAlert">Processing Failed</div>
      //       <div className="alertInfo">
      //         Apologies, We&apos;ve encountered an issue on our side. Rest assured, we&apos;re monitoring your request,
      //         and you&apos;ll receive an email notification once it&apos;s been processed.
      //       </div>
      //     </>
      //   }
      //   type="info"
      //   showIcon
      //   style={{
      //     position: 'fixed',
      //     top: 10,
      //     zIndex: 999,
      //     left: '35%',
      //     width: '400px',
      //     display: 'flex',
      //   }}
      // />;
    } catch (error) {
      dispatch({ type: 'SENDING_EMAIL_FAILED', payload: fileType });
      if (updateStore) {
        dispatch(ActionCreators.setSalesFileProcessStatus('FAILED'));
      }
      dispatch(
        ActionCreators.setErrorMessageNotificationAndStatus({
          message: 'An error occurred during the file processing. Please attempt the action once more.',
          status: true,
        })
      );
    }
  };
};
