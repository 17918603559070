import { setApiStatus } from '../ApiStatusActions';

export const SET_INVENTORY_FILE_DETAILS_BY_LOCATION = 'SET_INVENTORY_FILE_DETAILS_BY_LOCATION';

export function setUploadInventorySheetApiStatus(status) {
  const apiName = 'UploadInventorySheetApiStatus';
  return setApiStatus({ apiName, status });
}

export function setUploadInventoryTransitSheetApiStatus(status) {
  const apiName = 'UploadInventoryTransitSheetApiStatus';
  return setApiStatus({ apiName, status });
}

export const setInventoryFileDetailsByLocation = (data) => {
  return {
    type: SET_INVENTORY_FILE_DETAILS_BY_LOCATION,
    payload: data,
  };
};
