export const SET_CURRRENT_BRANCH = "SET_CURRENT_BRANCH";
export const SET_CURRENT_BRANCH_PREDICTION_DATA =
  "SET_CURRENT_BRANCH_PREDICTION_DATA";
export const SET_SALES_SHEET_DATA_BY_BRANCH = "SET_SALES_SHEET_DATA_BY_BRANCH";

export const setCurrentBranch = (data) => {
  return { type: SET_CURRRENT_BRANCH, payload: data };
};
export const setSlabPredictionDataByBranch = (data) => {
  return { type: SET_CURRENT_BRANCH_PREDICTION_DATA, payload: data };
};
export const setSalesSheetDataByBranch = (data) => {
  return { type: SET_SALES_SHEET_DATA_BY_BRANCH, payload: data };
};
