import { message } from 'antd';
import { Auth } from 'aws-amplify';
import { ForgotPassword } from 'aws-amplify-react';
import React from 'react';
import ForgotPasswordEmailSent from '../../Components/ForgotPasswordModal/ForgotPasswordEmailSent';
import ResetPassword from '../../Components/ForgotPasswordModal/ResetPassword';

class ForgotPasswordView extends ForgotPassword {
  constructor(props) {
    super(props);
    this.state = {
      emailId: '',
      currentState: 'setEmail',
      error: null,
    };
    this._validAuthStates = ['forgotPassword'];
    this.onSubmit = this.onSubmit.bind(this);
    this.resendLink = this.resendLink.bind(this);
    this.changeState = this.changeState.bind(this);
    this.onCancel = this.onCancel.bind(this);
  }

  changeState(state) {
    super.changeState(state);
  }

  onCancel() {
    this.setState({ emailId: '', currentState: 'setEmail', error: null });
    this.changeState('signIn');
  }

  async sendVerificationEmail(emailId) {
    try {
      await Auth.forgotPassword(emailId.toLowerCase());
      this.setState({
        currentState: 'emailSent',
        sendingVerificatoniEmail: false,
      });
    } catch (err) {
      this.setState({ sendingVerificatoniEmail: false });
      if (err.code === 'UserNotFoundException') {
        message.error('No User found');
      } else if (err.code === 'LimitExceededException') {
        message.error(err.message);
      } else if (err.code === 'InvalidParameterException') {
        message.error(err.message);
      } else {
        message.error('Something went wrong. Email sending failed');
      }
    }
  }

  onSubmit(emailId) {
    this.setState({
      emailId,
      sendingVerificatoniEmail: true,
    });
    this.sendVerificationEmail(emailId);
  }

  async resendLink() {
    const { emailId } = this.state;
    try {
      await Auth.forgotPassword(emailId.toLowerCase());
      message.success('Email Resent Successfully');
    } catch (err) {
      if (err.code === 'LimitExceededException') {
        message.error(err.message);
      } else if (err.code === 'InvalidParameterException') {
        message.error(err.message);
      } else {
        message.error('Something went wrong. Email sending failed');
      }
    }
  }

  loadContent() {
    const { currentState, sendingVerificatoniEmail } = this.state;
    if (currentState === 'setEmail') {
      return (
        <ResetPassword
          onSubmit={this.onSubmit}
          onCancel={this.onCancel}
          sendingVerificatoniEmail={sendingVerificatoniEmail}
        />
      );
    }
    return <ForgotPasswordEmailSent onResendEmail={this.resendLink} onCancel={this.onCancel} />;
  }

  showComponent() {
    return <div style={{ display: 'flex', justifyContent: 'center' }}>{this.loadContent()}</div>;
  }
}

export default ForgotPasswordView;
