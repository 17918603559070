import _ from 'lodash';
import * as ActionCreators from '../Actions/ActionCreators/AuthenticationActionCreator';

const initialState = {
  AuthChangePasswordRequired: {},
  AuthLoginError: {},
  SignInStatus: '',
};

const AuthenticationReducer = (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case ActionCreators.SET_AUTH_CHANGE_PASSWORD_REQUIRED:
      return {
        ...state,
        AuthChangePasswordRequired: payload,
      };
    case ActionCreators.SET_AUTH_LOGIN_ERROR:
      return {
        ...state,
        AuthLoginError: payload,
      };
    case ActionCreators.SET_SIGN_IN_STATUS:
      return {
        ...state,
        SignInStatus: payload,
      };
    case 'SET_AUTHENTICATED_USER':
      return {
        ...state,
        userAuthData: payload,
      };
    default:
      return state;
  }
};

export function getAuthChangePasswordRequiredStatus(state) {
  return state?.AuthenticationReducer?.AuthChangePasswordRequired;
}
export function getAuthLoginError(state) {
  return state?.AuthenticationReducer?.AuthLoginError;
}
export function getSignInStatus(state) {
  return state?.AuthenticationReducer?.SignInStatus;
}

export function getUserEmail(state) {
  return _.get(state, 'AuthenticationReducer.userAuthData.attributes.email', '');
}

export { AuthenticationReducer };
