import { SET_API_STATUS } from "../Actions/ApiStatusActions";

const initialState = {};

function ApiStatusReducer(state = initialState, action) {
  switch (action.type) {
    case SET_API_STATUS: {
      const { apiName, status } = action.payload;
      return {
        ...state,
        [apiName]: status,
      };
    }
    default:
      return state;
  }
}
function getApiStatus(state, apiName) {
  return state.ApiStatusReducer[apiName];
}

export { ApiStatusReducer, getApiStatus };
