import { InfoCircleFilled } from '@ant-design/icons';
import { Col, Row, Skeleton, Tooltip } from 'antd';
import dayjs from 'dayjs';
import React from 'react';
import { FileUploader } from 'react-drag-drop-files';
import { useDispatch, useSelector } from 'react-redux';
import { uploadInventorySheetData } from '../../Actions/SalesSheetActions';
import { getApiStatus } from '../../Reducers/ApiStatusReducer';
import {
  getInventoryFileDetailsByLocation,
  getInventoryTransitFileDetailsByLocation,
  getLatestSalesSheetData,
} from '../../Reducers/SalesSheetReducer';
import { differenceInUploadedDays, getLabelForDateDifferenceFromToday } from '../../Utils/SlabPredictionFormUtils';
import FileDownloadButton from '../Common/FileDownloadButton';
import './UploadedInventorySheetStyles.scss';

const fileTypes = ['XLSX'];

function UploadedInventorySheet(props) {
  const { fileUploadingDisplay, currentBranch, type } = props;

  const dispatch = useDispatch();

  const uploadInventorySheetApiStatus = useSelector((state) =>
    getApiStatus(state, type === 'inventory' ? 'UploadInventorySheetApiStatus' : 'UploadInventoryTransitSheetApiStatus')
  );
  const getLatestSalesSheetApiStatus = useSelector((state) => getApiStatus(state, 'SalesSheetApiStatus'));
  const { inventoryinfo, inventorytransitinfo } = useSelector((state) => getLatestSalesSheetData(state));
  const latestInventoryData = type === 'inventory' ? inventoryinfo : inventorytransitinfo;
  const inventoryFileDetailsByLocation =
    useSelector((state) => {
      if (type === 'inventory') return getInventoryFileDetailsByLocation(state);
      return getInventoryTransitFileDetailsByLocation(state);
    }) ?? {};

  const lastUpdatedInDays = differenceInUploadedDays(latestInventoryData?.last_uploaded_date);

  const uploadInventorySheet = async (file) => {
    const inventoryData = {
      file_name: file[0]?.name,
      xlsx_data: '',
      branch: currentBranch,
      file_type: type === 'inventory' ? 'Inventory' : 'InventoryTransit',
    };
    await new Promise((resolve) => {
      const reader = new FileReader();
      reader.readAsDataURL(file[0]);
      reader.onloadend = () => {
        inventoryData.xlsx_data = reader?.result?.replace(/^data:.+;base64,/, '');
        resolve();
      };
    });
    dispatch(uploadInventorySheetData(inventoryData, type));
  };

  const uploadNewFileDisplay =
    uploadInventorySheetApiStatus === 'INPROGRESS' ? null : (
      <FileUploader multiple={true} handleChange={uploadInventorySheet} name="file" types={fileTypes}>
        <div className="uploaded-inventory-sheet-upload-btn">Upload New</div>
      </FileUploader>
    );

  const fileDownloadButton = <FileDownloadButton downloadFileName="Inventory_demo" fileName="Inventory_demo.xlsx" />;

  const uploadSheetWrapper = (
    <div>
      <div className="uploaded-inventory-sheet-header">
        <div className="label-and-info-section">
          <div className="label-name">
            {type === 'inventory' ? 'Inventory' : 'Inventory Transit'} Sheet {fileDownloadButton}
          </div>
        </div>
        <FileUploader multiple={true} handleChange={uploadInventorySheet} name="file" types={fileTypes}>
          <div className="uploaded-inventory-sheet-content">
            <div className="uploaded-inventory-sheet-left-content uploadFile">
              <img
                src={`${process.env.PUBLIC_URL}/static/Images/xlsx_icon.svg`}
                alt="file logo"
                width={32}
                height={32}
              />
              {uploadInventorySheetApiStatus !== 'INPROGRESS' && (
                <span className="selectFileContent">{'Select a inventory sheet file to upload'}</span>
              )}
              <div className="uploaded-inventory-sheet-right-content">
                <div>{uploadInventorySheetApiStatus === 'INPROGRESS' && fileUploadingDisplay}</div>
              </div>
            </div>
          </div>
        </FileUploader>
        <div className="supportedFormInfo">Supported: xlsx</div>
      </div>
    </div>
  );

  const displayFileName =
    (latestInventoryData?.file_name || inventoryFileDetailsByLocation[`${currentBranch}`]?.file_name) ??
    'Inventory sheet.xlsx';

  const uploadedSheetWrapper = (
    <div className="uploaded-inventory-sheet-header">
      <div className="label-and-info-section">
        <div className="label-name">
          {type === 'inventory' ? 'Inventory' : 'Inventory Transit'} Sheet {fileDownloadButton}
        </div>
        <div className="uploaded-inventory-sheet-last-updated">
          {getLabelForDateDifferenceFromToday(lastUpdatedInDays)}
        </div>
      </div>
      <div className="uploaded-inventory-sheet-content">
        <Row style={{ width: '100%' }} gutter={[0, 2]}>
          <Col xs={24} sm={24} md={24} lg={15} xl={15} xxl={15}>
            <div className="uploaded-inventory-sheet-left-content">
              <img
                src={`${process.env.PUBLIC_URL}/static/Images/xlsx_icon.svg`}
                alt="arya logo"
                width={32}
                height={32}
              />
              <div
                style={{
                  paddingLeft: '14px',
                  color: '#1F2730',
                  fontWeight: 500,
                  wordBreak: 'break-all',
                  overflow: 'hidden',
                  textOverflow: 'ellipsis',
                  whiteSpace: 'nowrap',
                  width: '130px',
                }}
              >
                {' '}
                <Tooltip title={displayFileName}>{`${displayFileName?.substring(0, 45)}...`}</Tooltip>
              </div>
            </div>
          </Col>
          <Col xs={24} sm={24} md={24} lg={9} xl={9} xxl={9}>
            <div className="uploaded-inventory-sheet-right-content">
              <div>
                {uploadInventorySheetApiStatus === 'INPROGRESS' && fileUploadingDisplay}
                {uploadNewFileDisplay}
              </div>
            </div>
          </Col>
        </Row>
      </div>
      <div
        style={{
          fontSize: '12px',
          color: '#51575E',
          display: 'flex',
          gap: '8px',
          paddingTop: '12px',
        }}
      >
        {latestInventoryData?.actual_date ? (
          <>
            <div>
              <InfoCircleFilled style={{ color: '#1890FF' }} />
            </div>
            <div> Latest Product sale date is {dayjs(latestInventoryData?.actual_date).format('DD/MM/YYYY')}</div>
          </>
        ) : (
          <br />
        )}
      </div>
    </div>
  );

  const displayInventoryComponent =
    latestInventoryData?.file_name || inventoryFileDetailsByLocation[`${currentBranch}`]?.file_name
      ? uploadedSheetWrapper
      : uploadSheetWrapper;

  return (
    <Skeleton loading={getLatestSalesSheetApiStatus === 'INPROGRESS'}>
      <div className="uploaded-inventory-sheet-wrapper">{displayInventoryComponent}</div>
    </Skeleton>
  );
}

export default UploadedInventorySheet;
