import { Col, Row } from 'antd';
import React from 'react';
import './ForgotPasswordEmailSent.scss';

export default function ForgotPasswordEmailSent(props) {
  const { onResendEmail, onCancel } = props;
  return (
    <Row>
      <Col xs={1} sm={1} lg={1} md={1} xl={1} xxl={1} />
      <Col xs={22} sm={22} lg={22} md={22} xl={22} xxl={22}>
        <div
          style={{
            marginTop: '150px',
            width: '100%',
            backgroundColor: '#FFFFFF',
            borderRadius: '8px',
            fontFamily: 'AvertaStd-Regular',
            paddingBottom: '10px',
          }}
        >
          <div
            style={{
              width: '82%',
              paddingLeft: '42px',
              paddingTop: '30px',
              color: '#83878C',
            }}
          >
            <div style={{ fontFamily: '' }}>
              If your email address exists in our database, you will receive a password recovery link at your email
              address in a few minutes.
            </div>
          </div>
          <div
            style={{
              paddingLeft: '42px',
              paddingTop: '30px',
              color: '#83878C',
              fontWeight: 600,
            }}
          >
            Did not receive email?
          </div>
          <div style={{ paddingTop: '20px', fontSize: '14px' }}>
            {' '}
            <a
              style={{
                color: '#007BFF',
                paddingLeft: '42px',
                fontFamily: 'AvertaStd-Regular',
              }}
              onClick={onResendEmail}
              href="#top"
            >
              Resend email
            </a>
          </div>
          <div className="send-another-email" style={{ paddingTop: '10px' }}>
            {' '}
            <a
              style={{
                color: '#007BFF',
                paddingLeft: '42px',
                fontFamily: 'AvertaStd-Regular',
              }}
              onClick={onCancel}
              href="#top"
            >
              Return to Log in
            </a>
          </div>
        </div>
      </Col>

      <Col xs={1} sm={1} lg={1} md={1} xl={1} xxl={1} />
    </Row>
  );
}
