import { Col, Row } from 'antd';
import React from 'react';
import { useDispatch } from 'react-redux';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import { setAuthenticatedUser } from './Actions/AuthenticationActions';
import LandingPageContainer from './Containers/LandingPageContainer/LandingPageContainer';
import OnBoardingUserContainer from './Containers/OnBoardingUserContainer/OnBoardingUserContainer';
import ResetForgotPassword from './Containers/ResetForgotPassword/ResetForgotPassword';

function Routes() {
  const dispatch = useDispatch();
  dispatch(setAuthenticatedUser());

  return (
    <Row>
      <Col xs={2} sm={2} lg={2} md={2} xl={2} xxl={2} />
      <Col xs={20} sm={20} lg={20} md={20} xl={20} xxl={20}>
        <Router>
          <Switch>
            <Route path="/resetPassword" component={ResetForgotPassword} />
            <Route path="/slabPrediction" component={LandingPageContainer} />
            <Route path="/*" component={OnBoardingUserContainer} />
          </Switch>
        </Router>
      </Col>
      <Col xs={2} sm={2} lg={2} md={2} xl={2} xxl={2} />
    </Row>
  );
}

export default Routes;
