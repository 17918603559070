export const SET_AUTH_CHANGE_PASSWORD_REQUIRED =
  "SET_AUTH_CHANGE_PASSWORD_REQUIRED";

export const SET_AUTH_LOGIN_ERROR = "SET_AUTH_LOGIN_ERROR";

export const SET_SIGN_IN_STATUS = "SET_SIGN_IN_STATUS";

export function setAuthChangePasswordRequired(data) {
  return { type: SET_AUTH_CHANGE_PASSWORD_REQUIRED, payload: data };
}
export function setAuthLoginError(data) {
  return { type: SET_AUTH_LOGIN_ERROR, payload: data };
}
export function setSignInStatus(data) {
  return { type: SET_SIGN_IN_STATUS, payload: data };
}
