import * as InventoryActionCreators from '../Actions/ActionCreators/InventorySheetActionCreator';
import * as ActionCreators from '../Actions/ActionCreators/SalesSheetActionCreator';

const initialState = {
  SalesSheetData: {},
  SalesFileDetailsByLocation: {},
  InventoryFileDetailsByLocation: {},
  InventoryTransitFileDetailsByLocation: {},
  RefreshNotificationVisibility: false,
  ErrorMessageNotificationAndStatus: {},
};

const SalesSheetReducer = (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case ActionCreators.SET_SALES_SHEET_DATA:
      return {
        ...state,
        SalesSheetData: payload,
      };
    case ActionCreators.SET_ERROR_MESSAGE_NOTIFICATION_AND_STATUS:
      return {
        ...state,
        ErrorMessageNotificationAndStatus: payload,
      };
    case ActionCreators.SET_SALES_FILE_DETAILS_BY_LOCATION:
      return {
        ...state,
        SalesSheetData: { ...state.SalesSheetData, ...{ salesinfo: Object.values(payload)[0] } },
        SalesFileDetailsByLocation: payload,
      };
    case InventoryActionCreators.SET_INVENTORY_FILE_DETAILS_BY_LOCATION:
      const dataToUpdate = Object.values(payload)[0];
      if (dataToUpdate.file_type === 'Inventory')
        return {
          ...state,
          SalesSheetData: { ...state.SalesSheetData, ...{ inventoryinfo: dataToUpdate } },
          InventoryFileDetailsByLocation: payload,
        };
      else
        return {
          ...state,
          SalesSheetData: { ...state.SalesSheetData, ...{ inventorytransitinfo: dataToUpdate } },
          InventoryTransitFileDetailsByLocation: payload,
        };
    case ActionCreators.SET_REFRESH_NOTIFICATION_VISBILITY:
      return {
        ...state,
        RefreshNotificationVisibility: payload,
      };

    default:
      return state;
  }
};

export function getLatestSalesSheetData(state) {
  return state?.SalesSheetReducer?.SalesSheetData;
}

export const getSalesFileDetailsByLocation = (state) => {
  return state?.SalesSheetReducer?.SalesFileDetailsByLocation;
};

export const getInventoryFileDetailsByLocation = (state) => {
  return state?.SalesSheetReducer?.InventoryFileDetailsByLocation;
};

export const getInventoryTransitFileDetailsByLocation = (state) => {
  return state?.SalesSheetReducer?.InventoryTransitFileDetailsByLocation;
};

export const getRefreshNotificationVisibilityStatus = (state) => {
  return state?.SalesSheetReducer?.RefreshNotificationVisibility;
};

export const getErrorMessageNotificationAndStatus = (state) => {
  return state?.SalesSheetReducer?.ErrorMessageNotificationAndStatus;
};

export { SalesSheetReducer };
