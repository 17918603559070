import { Button } from "antd";
import { FileTextFilled } from "@ant-design/icons";
import React from "react";
import config from "../../Config/Config.json";

const downloadFile = (fileLink, fileName) => {
  const downloadLink = document.createElement("a");
  downloadLink.href = fileLink;
  downloadLink.download = fileName;
  downloadLink.click();
};

const FileDownloadButton = ({ downloadFileName, fileName }) => {
  const handleDownload = () => {
    downloadFile(`${config.urls.demoFilesS3Url}/${fileName}`, downloadFileName);
  };

  return (
    <Button type="link" onClick={handleDownload} style={{fontSize: "14px",padding:'4px 2px'}}>
      <u>
        <FileTextFilled style={{ color: "blue", fontSize: "14px" }} />
        Download Sample
      </u>
    </Button>
  );
};

export default FileDownloadButton;
