import _ from 'lodash';
import uuid from 'uuid';

export const enrichPredictionTable = (predictionTable) => {
  if (!predictionTable) return [];
  let enrichedPredictionTable1 = [];
  let keyCount = 1;

  predictionTable.forEach((element) => {
    enrichedPredictionTable1.push({
      key: uuid.v4(),
      status: '',
      productNumber: keyCount,
      product: [element.Name, element.ProductStatus],
      quantity: element.OrderQuantity,
      confidence: element.Confidence,
      prediction: 'NA',
    });
    keyCount = keyCount + 1;
  });

  return enrichedPredictionTable1;
};

export const enrichPredictionTableForDownload = (predictionTable, allDataPrediction) => {
  if (!predictionTable) return [];
  let predictionTableForDownload = [];
  predictionTableForDownload.push([
    'Sr No.',
    'Product Name',
    'Order Quantity(sq.ft)',
    'Confidence(%)',
    'Product Status',
  ]);
  // predictionTableForDownload.push([
  //   1,
  //   currentBranch ? 'All' ,
  //   allDataPrediction?.quantity?.toString(),
  //   allDataPrediction?.confidence?.toString(),
  //   allDataPrediction?.status?.toString(),
  // ]);
  let keyCount = 1;
  predictionTable.forEach((result) => {
    predictionTableForDownload.push([
      keyCount.toString(),
      result?.product?.length > 0 ? result.product[0] : '',
      result?.quantity?.toString(),
      result?.confidence?.toString(),
      result?.status,
    ]);
    keyCount = keyCount + 1;
  });

  return predictionTableForDownload;
};

export const getFilteredPredictionTableBySelectedProduct = (selectedProductList, enrichedPredictionTable) => {
  if (_.isEmpty(enrichedPredictionTable)) return {};
  let filteredEnrichedPredictionTable = [];
  selectedProductList.forEach((productName) => {
    enrichedPredictionTable.forEach((predictionTable) => {
      if (predictionTable?.product[0] === productName) {
        filteredEnrichedPredictionTable.push(predictionTable);
      }
    });
  });
  return filteredEnrichedPredictionTable;
};
