import React, { useEffect } from "react";
import { Form, Input, Button, Col, Row } from "antd";
import { loginFormContent } from "../../Utils/ContentUtils/LoginFormContent";
import { emailValidator } from "../../Utils/ContentUtils/Validators";
import "./LoginForm.scss";

function LoginForm(props) {
  const {
    onHandleChange,
    onSubmit,
    onForgotPassword,
    username,
    password,
    loading,
    loginError,
  } = props;

  const [userEmail, setUserEmail] = React.useState("");
  const [userPassword, setUserPassword] = React.useState("");
  const [emailInputValidationError, setEmailInputValidationError] =
    React.useState("");
  const [passwordInputValidationError, setPasswordInputValidationError] =
    React.useState("");

  const validateEmail = (email) => {
    let errorMessage = "";
    if (!email?.trim()) {
      errorMessage = "Please enter the email address.";
    } else if (!emailValidator(email)) {
      errorMessage = "Please enter a valid email address.";
    }
    return errorMessage;
  };

  useEffect(() => {
    if (username) {
      const emailValidationMessage = validateEmail(username);
      setEmailInputValidationError(emailValidationMessage);
      setUserEmail(username);
    }
    if (password) {
      setPasswordInputValidationError("");
      setUserPassword(password);
    }
  }, [username, password]);

  const onUserEmailChange = (e) => {
    const email = e.target.value;
    const emailValidationMessage = validateEmail(email);
    setEmailInputValidationError(emailValidationMessage);
    setUserEmail(email);
  };

  const onUserPasswordChange = (e) => {
    const { value } = e.target;
    let errorMessage = "";
    if (!value) {
      errorMessage = "Please enter the password.";
    }
    setPasswordInputValidationError(errorMessage);
    setUserPassword(value);
  };

  const onHandleUsernameChange = (e) => {
    onUserEmailChange(e);
    onHandleChange(e);
  };

  const onHandlePasswordChange = (e) => {
    onUserPasswordChange(e);
    onHandleChange(e);
  };

  const isInputValid = () => {
    let isValid = true;

    onUserEmailChange({
      target: {
        value: userEmail,
      },
    });
    onUserPasswordChange({
      target: {
        value: userPassword,
      },
    });

    if (
      !userEmail?.trim() ||
      !userPassword?.trim() ||
      passwordInputValidationError ||
      emailInputValidationError
    ) {
      isValid = false;
    }

    return isValid;
  };

  const onClickSubmit = () => {
    if (!isInputValid()) {
      return;
    }
    onSubmit();
  };
  return (
    <Row style={{ width: "100%" }}>
      <Col xs={1} sm={4} lg={7} md={6} xl={8} xxl={9} />
      <Col xs={22} sm={16} lg={10} md={12} xl={8} xxl={6}>
        <div className="login-form-wrapper">
          <div className="login-form-header">
            <div className="login-form-header-signin">
              {loginFormContent.SignIn}
            </div>
            <div className="login-form-header-welcome-back">
              {loginFormContent.WelcomeBack}
            </div>
          </div>

          <div className="login-form-inputs">
            <div className="login-form-email-label">Email</div>
            <Form.Item
              validateStatus={emailInputValidationError ? "error" : "success"}
              help={emailInputValidationError}
            >
              <Input
                id="username"
                key="username"
                name="username"
                required
                onChange={onHandleUsernameChange}
                onPressEnter={
                  emailInputValidationError || passwordInputValidationError
                    ? () => {}
                    : onSubmit
                }
                placeholder="Enter Email"
                value={userEmail}
              />
            </Form.Item>
            <div className="login-form-password-label">Password</div>
            <Form.Item
              name="password"
              validateStatus={
                passwordInputValidationError ? "error" : "success"
              }
              help={passwordInputValidationError}
            >
              <Input.Password
                id="password"
                key="password"
                name="password"
                required
                onChange={onHandlePasswordChange}
                onPressEnter={
                  emailInputValidationError || passwordInputValidationError
                    ? () => {}
                    : onSubmit
                }
                type="password"
                placeholder="Enter Password"
                value={password}
              />
            </Form.Item>
          </div>
          <div className="login-form-error">{loginError}</div>
          <div className="login-form-footer">
            <Button
              type="primary"
              htmlType="submit"
              block
              onClick={onClickSubmit}
              loading={loading}
              className="login-btn"
              style={{ cursor: "pointer" }}
            >
              {loginFormContent.SignIn}
            </Button>
            <div
              type="link"
              onClick={onForgotPassword}
              className="login-form-forgot-password"
            >
              {loginFormContent.ForgotPassword}
            </div>
          </div>
        </div>
      </Col>
      <Col xs={1} sm={4} lg={7} md={6} xl={7} xxl={7} />
    </Row>
  );
}

export default LoginForm;
