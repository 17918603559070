import { Progress, Skeleton, Table } from 'antd';
import _ from 'lodash';
import React, { useEffect, useState } from 'react';
import {
  getInStockProductsList,
  getLowStockProductsList,
  getOverStockedProductsList,
  IN_STOCK,
  LOW_STOCK,
  OVER_STOCKED,
  productStatusColorMapping,
} from '../../Utils/SlabPredictionFormUtils';
import SlabPredictionFilters from './SlabPredictionFilters/SlabPredictionFilters';
import './SlabPredictionTable.scss';

export const getProductStatusColor = (status) => {
  switch (status) {
    case OVER_STOCKED:
      return <div className="product-card-red-dot" />;
    case IN_STOCK:
      return <div className="product-card-green-dot" />;
    case LOW_STOCK:
      return <div className="product-card-yellow-dot" />;
    default:
      return <></>;
  }
};

const columns = [
  {
    tilte: '',
    dataIndex: 'status',
    key: 'status',
    width: '3%',
    render: (productStatus) => getProductStatusColor(productStatus),
  },
  {
    title: <div style={{ fontFamily: 'AvertaStd-Regular', fontWeight: 500 }}>No</div>,
    dataIndex: 'productNumber',
    key: 'productNumber',
    width: '5%',
    render: (text) => <div style={{ fontFamily: 'AvertaStd-Semibold', fontWeight: 500 }}>{text}</div>,
    responsive: ['md'],
  },
  {
    title: <div style={{ fontFamily: 'AvertaStd-Regular', fontWeight: 500 }}>Product Name</div>,
    dataIndex: 'product',
    key: 'product',
    width: '26%',
    render: (_, { product }) => (
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          fontFamily: 'AvertaStd-Semibold',
        }}
      >
        <div style={{ fontWeight: 500 }}>{product[0]}</div>
        {product[1] === OVER_STOCKED || product[1] === LOW_STOCK ? (
          <div style={{ color: '#83878C' }}>{product[1]}</div>
        ) : null}
      </div>
    ),
  },

  {
    title: <div style={{ fontFamily: 'AvertaStd-Regular', fontWeight: 500 }}>In Stock/ Required</div>,
    dataIndex: 'prediction',
    key: 'prediction',
    // render: (text) => (
    //   <div
    //     style={{
    //       fontWeight: 500,
    //       fontSize: "13px",
    //       paddingLeft: "10px",
    //       fontFamily: "AvertaStd-Semibold",
    //     }}
    //   >
    //     {text}
    //   </div>
    // ),

    width: '19%',
    render: (_, { prediction }) => (
      <div>
        <div>
          {prediction[0]} / {prediction[1]}
        </div>
        <Progress
          percent={(prediction[2] / prediction[3]) * 100}
          showInfo={false}
          style={{ width: '62px' }}
          className="slab-prediction-table-progress-bar"
          strokeColor={productStatusColorMapping[prediction[4]]}
        />
      </div>
    ),
  },
  {
    title: <div style={{ fontFamily: 'AvertaStd-Regular', fontWeight: 500 }}>Confidence</div>,
    dataIndex: 'confidence',
    key: 'confidence',
    render: (text) => (
      <div
        style={{
          fontWeight: 500,
          fontSize: '13px',
          paddingLeft: '10px',
          fontFamily: 'AvertaStd-Semibold',
        }}
      >
        {text}%
      </div>
    ),
    width: '15%',
  },
  {
    title: (
      <div className="slab-prediction-table-quantity-order">
        <span>QTY to Order</span>
        {/* <img
      src={`${process.env.PUBLIC_URL}/static/Images/sortby.png`}
      alt="sort by"
      width={18}
      height={18}
    /> */}
      </div>
    ),
    dataIndex: 'quantity',
    key: 'quantity',
    sorter: (a, b) => {
      console.table({
        a: parseInt(a.absoluteQuantity),
        b: parseInt(b.absoluteQuantity),
        difference: a.absoluteQuantity - b.absoluteQuantity,
      });
      // console.log('a absoluteQuantity,', a.absoluteQuantity);
      // console.log('b absoluteQuantity,', b.absoluteQuantity);
      // console.log('difference,', parseInt(a.absoluteQuantity) - parseInt(b.absoluteQuantity));
      return a.absoluteQuantity - b.absoluteQuantity;
    },
    render: (text) => (
      <div
        style={{
          fontWeight: 500,
          fontSize: '13px',
          paddingLeft: '14px',
          fontFamily: 'AvertaStd-Semibold',
        }}
      >
        {text ?? '0'} <span style={{ color: '#83878C' }}>sq. ft</span>{' '}
      </div>
    ),
    width: '12%',
  },
];

function SlabPredictionTable(props) {
  const { generateTableApiStatus, setDataToDownload, displayPredictionTable } = props;
  const [initialDisplayPredictionTable, setInitialDisplayPredictionTable] = useState(displayPredictionTable);
  const [selectedFilter, setSelectedFilter] = useState([]);

  React.useEffect(() => {
    setDataToDownload(initialDisplayPredictionTable);
  }, [initialDisplayPredictionTable]);

  const onClearFilter = (value) => {
    const inStockProducts = getInStockProductsList(displayPredictionTable);
    const overStockedProducts = getOverStockedProductsList(displayPredictionTable);
    const lowStockProducts = getLowStockProductsList(displayPredictionTable);
    switch (value) {
      case OVER_STOCKED:
        if (selectedFilter.length === 1) {
          setInitialDisplayPredictionTable(displayPredictionTable);
          setSelectedFilter([]);
        } else {
          let mergedArray = [];
          selectedFilter.forEach((filter) => {
            if (filter === IN_STOCK) {
              mergedArray = [...mergedArray, ...inStockProducts];
            } else if (filter === LOW_STOCK) {
              mergedArray = [...mergedArray, ...lowStockProducts];
            }
          });
          setInitialDisplayPredictionTable(mergedArray);
          const updatedSelectedFilter = selectedFilter.filter((selected) => selected !== OVER_STOCKED);
          setSelectedFilter(updatedSelectedFilter);
        }
        break;
      case IN_STOCK:
        if (selectedFilter.length === 1) {
          setInitialDisplayPredictionTable(displayPredictionTable);
          setSelectedFilter([]);
        } else {
          let mergedArray = [];
          selectedFilter.forEach((filter) => {
            if (filter === LOW_STOCK) {
              mergedArray = [...mergedArray, ...lowStockProducts];
            } else if (filter === OVER_STOCKED) {
              mergedArray = [...mergedArray, ...overStockedProducts];
            }
          });
          setInitialDisplayPredictionTable(mergedArray);
          const updatedSelectedFilter = selectedFilter.filter((selected) => selected !== IN_STOCK);
          setSelectedFilter(updatedSelectedFilter);
        }
        break;
      case LOW_STOCK:
        if (selectedFilter.length === 1) {
          setInitialDisplayPredictionTable(displayPredictionTable);
          setSelectedFilter([]);
        } else {
          let mergedArray = [];
          selectedFilter.forEach((filter) => {
            if (filter === IN_STOCK) {
              mergedArray = [...mergedArray, ...inStockProducts];
            } else if (filter === OVER_STOCKED) {
              mergedArray = [...mergedArray, ...overStockedProducts];
            }
          });
          const updatedSelectedFilter = selectedFilter.filter((selected) => selected !== LOW_STOCK);
          setInitialDisplayPredictionTable(mergedArray);
          setSelectedFilter(updatedSelectedFilter);
        }
        break;
      default:
        setInitialDisplayPredictionTable(null);
    }
  };

  const onSelectFilter = (value) => {
    switch (value) {
      case IN_STOCK:
        const inStockProducts = getInStockProductsList(displayPredictionTable);
        if (selectedFilter.length > 0) setInitialDisplayPredictionTable((prev) => [...inStockProducts, ...prev]);
        else setInitialDisplayPredictionTable(inStockProducts);
        break;
      case OVER_STOCKED:
        const overStockedProducts = getOverStockedProductsList(displayPredictionTable);
        if (selectedFilter.length > 0) {
          setInitialDisplayPredictionTable((prev) => [...overStockedProducts, ...prev]);
        } else {
          setInitialDisplayPredictionTable(overStockedProducts);
        }
        break;
      case LOW_STOCK:
        const lowStockProducts = getLowStockProductsList(displayPredictionTable);
        if (selectedFilter.length > 0) {
          setInitialDisplayPredictionTable((prev) => [...lowStockProducts, ...prev]);
        } else {
          setInitialDisplayPredictionTable(lowStockProducts);
        }
        break;
      default:
        setInitialDisplayPredictionTable(null);
    }
    setSelectedFilter((prev) => [...prev, value]);
  };
  useEffect(() => {
    // if (displayPredictionTable?.length > 0 && selectedFilter?.length === 0)
    setInitialDisplayPredictionTable(_.cloneDeep(displayPredictionTable));
  }, [displayPredictionTable]);

  return (
    <div style={{ width: '100%' }}>
      <SlabPredictionFilters
        selectedFilter={selectedFilter}
        displayPredictionTable={displayPredictionTable}
        onSelectFilter={onSelectFilter}
        onClearFilter={onClearFilter}
      />
      <Skeleton loading={generateTableApiStatus === 'INPROGRESS'}>
        <Table
          columns={columns}
          dataSource={initialDisplayPredictionTable}
          pagination={false}
          scroll={{
            y: 360,
          }}
          rowClassName="slab-prediction-table-row"
          className="slab-prediction-table"
        />
      </Skeleton>
    </div>
  );
}

export default SlabPredictionTable;
