import cleanSet from 'clean-set';
import _ from 'lodash';
import * as ActionCreators from '../Actions/ActionCreators/BranchActionCreator';
import config from '../Config/Config.json';

const initialState = {
  CurrentBranch: _.get(config, 'locations[0].label'),
  Branches: [],
  SlabPredictionDataByBranch: {},
};

const BranchReducer = (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case ActionCreators.SET_CURRRENT_BRANCH: {
      let newState;
      newState = cleanSet(state, 'CurrentBranch', payload);
      return newState;
    }
    case ActionCreators.SET_CURRENT_BRANCH_PREDICTION_DATA: {
      let newState;
      const { startDate, data } = payload;
      const newPredictionData = {};
      newPredictionData[startDate] = data;
      const currentBranch = state?.CurrentBranch;

      newState = cleanSet(state, ['SlabPredictionDataByBranch', currentBranch, 'SlabPredictionData'], data);
      if (_.isEmpty(newState?.Branches)) {
        newState = cleanSet(newState, 'Branches', [currentBranch]);
      } else if (!newState?.Branches?.includes(currentBranch)) {
        newState = cleanSet(newState, 'Branches', [...newState.Branches, currentBranch]);
      }
      if (_.isEmpty(newState.SlabPredictionDataByBranch[currentBranch].SlabPredictionDataByDate)) {
        newState = cleanSet(newState, ['SlabPredictionDataByBranch', currentBranch, 'SlabPredictionDataByDate'], {
          ...newPredictionData,
        });
      } else {
        newState = cleanSet(newState, ['SlabPredictionDataByBranch', currentBranch, 'SlabPredictionDataByDate'], {
          ...state.SlabPredictionDataByBranch[currentBranch].SlabPredictionDataByDate,
          ...newPredictionData,
        });
      }

      newState = cleanSet(
        newState,
        ['SlabPredictionDataByBranch', currentBranch, 'SlabPredictionCurrentStartDate'],
        startDate
      );
      if (_.isEmpty(state?.SlabPredictionDataByBranch[currentBranch]?.SlabPredictionStartDates)) {
        newState = cleanSet(
          newState,
          ['SlabPredictionDataByBranch', currentBranch, 'SlabPredictionStartDates'],
          [startDate]
        );
      } else if (!state?.SlabPredictionDataByBranch[currentBranch]?.SlabPredictionStartDates?.includes(startDate)) {
        newState = cleanSet(
          newState,
          ['SlabPredictionDataByBranch', currentBranch, 'SlabPredictionStartDates'],
          [...state.SlabPredictionDataByBranch[currentBranch].SlabPredictionStartDates, startDate]
        );
      }

      return newState;
    }

    case ActionCreators.SET_SALES_SHEET_DATA_BY_BRANCH: {
      let newState;
      const currentBranch = state?.CurrentBranch;
      newState = cleanSet(state, ['SlabPredictionDataByBranch', currentBranch, 'SalesSheetData'], payload);
      return newState;
    }
    default:
      return state;
  }
};

export function getCurrentBranch(state) {
  return state?.BranchReducer?.CurrentBranch;
}
export function getCurrentBranchSlabPredictionData(state) {
  return state?.BranchReducer?.SlabPredictionDataByBranch[state?.BranchReducer?.CurrentBranch]?.SlabPredictionData;
}
export function getSlabPredictionStartDatesByBranch(state) {
  return state?.BranchReducer?.SlabPredictionDataByBranch[state?.BranchReducer?.CurrentBranch]
    ?.SlabPredictionStartDates;
}
export function getSlabPredictionCurrentStartDateByBranch(state) {
  return state?.BranchReducer?.SlabPredictionDataByBranch[state?.BranchReducer?.CurrentBranch]
    ?.SlabPredictionCurrentStartDate;
}
export function getLatestSalesSheetDataByBranch(state) {
  return state?.BranchReducer?.SlabPredictionDataByBranch[state?.BranchReducer?.CurrentBranch]?.SalesSheetData;
}
export function getFetchedBranches(state) {
  return state?.BranchReducer?.Branches;
}
export { BranchReducer };
